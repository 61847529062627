import { onAuthStateChanged } from 'firebase/auth'
import { firebaseApp, firebaseAuth, firebaseDatabase, firestore } from '../Firebase'
import './PaywallPage.css'
import { get, child, ref, update } from 'firebase/database'
import { collection, getDocs } from 'firebase/firestore'
import { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { createCheckoutSession, getStripePayments } from '@stripe/firestore-stripe-payments'
import CallbackButton from '../components/buttons/CallbackButton'
import Button from '../components/buttons/Button'

import axios from 'axios'

function CallToAction(props) {
  const [userHasAccount, setUserHasAccount] = useState(firebaseAuth.currentUser !== null)

  onAuthStateChanged(firebaseAuth, () => {
    if (firebaseAuth.currentUser && !userHasAccount) {
      setUserHasAccount(true)
    }
  })

  if (userHasAccount) {
    return (
      <CallbackButton enabled={false} onClick={props.startPayment} innerText="Join the Club!" />
    )
  } else {
    return (
      <Button link="/signup" text="Create your account." />
    )
  }
}

function TrialPeriodText(props) {
  if (props.trialIsAvailable || !firebaseAuth.currentUser) {
    return (  
      <span style={{color: "white", marginTop: "5px"}}>Not ready to join us yet? You can also&nbsp;
      {firebaseAuth.currentUser?
      (
       <span className='clickable' style={{color: "lightblue", textDecoration: "underline"}} onClick={async () => {
        await update(ref(firebaseDatabase, 'userTrialTracker'), {
          [firebaseAuth.currentUser.uid]: Date.now()+1_209_600_000 // number milliseconds in two weeks
        })

        props.setRedirect({code: 1})
      }}>activate a free two week trial today.</span>
      ):(
        <Link to="/signup" style={{display: "inline"}}>
          <span className='clickable' style={{color: "lightblue", textDecoration: "underline"}}>
            activate a free two week trial</span>
        </Link>
      )}&nbsp;
       today. Don't worry, you won't have
            to enter any credit card information until after the trial is over.
      </span>
    )
  }
}

function TrialButton(props) {
  if (props.trialIsAvailable) {
    return (
      <CallbackButton innerText="Start my trial" onClick={async () => {
        await update(ref(firebaseDatabase, 'userTrialTracker'), {
          [firebaseAuth.currentUser.uid]: Date.now()+1_209_600_000 // number milliseconds in two weeks
        })

        props.setRedirect({code: 1})
      }}/>
    )
  }
}

function HeaderCTA(props) {
  if (firebaseAuth.currentUser) {
    return (
      <h3 className='signup-page-link' style={{textAlign: "center"}} onClick={async () => {
        await update(ref(firebaseDatabase, 'userTrialTracker'), {
          [firebaseAuth.currentUser.uid]: Date.now()+1_209_600_000 // number milliseconds in two weeks
        })

        props.setRedirect({code: 1})
      }}>Ready to go? <span className='clickable' style={{textDecoration: "underline", color: "lightblue"}}>Start your free two week trial now.</span></h3>
    )
  } else {
    return (
      <Link to="/signup">
        <h3 className='signup-page-link' style={{textAlign: "center"}}>Ready to go? <span style={{textDecoration: "underline", color: "lightblue"}}>Create an account to start your free two week trial now.</span></h3>
      </Link>
    )
  }
}

export default function PaywallPage(props) {
  const [redirect, setRedirect] = useState({code: 0})
  const [isLoading, setIsLoading] = useState(false)
  const [isTrialAvailable, setIsTrialAvailable] = useState({status: false, verified: false})

  async function checkTrialStatus() {
    if (!isTrialAvailable.verified) {
      let time = null
      try {
        time = await axios.get("http://worldclockapi.com/api/json/utc/now")
        time = Date.parse(time.data.currentDateTime)
      } catch (error) {
        time = Date.now()
      }

      let trialTracker = await get(child(ref(firebaseDatabase), 'userTrialTracker/' + firebaseAuth.currentUser.uid))
      if (!trialTracker.exists()) {
        setIsTrialAvailable({status: true, verified: true})
      } else {
        if (trialTracker.val() > time) {
          setRedirect({code: 1})
        }
        setIsTrialAvailable({status: false, verified: true})
      }
    }
  }

  async function updatePaymentStatus() {
    return new Promise(async (resolve, reject) => {
      let snapshot = await get(child(ref(firebaseDatabase), "userHasPaid/" + firebaseAuth.currentUser.uid))
      if (snapshot.val()) {
        setRedirect({code: 1})
        resolve(true)
      } else {
        const paymentColl = collection(firestore, "customers/" + firebaseAuth.currentUser.uid + "/payments")
        const paymentSnap = await getDocs(paymentColl)
        try {
          for (let doc of paymentSnap.docs) {
            if (doc.data().items[0].price.product === "prod_MSRpKnkFK2HQn4") {
              // update in database for this customer
              update(ref(firebaseDatabase, "userHasPaid"), {
                [firebaseAuth.currentUser.uid]: true
              }).then(() => {
                setRedirect({code: 1})
                resolve(true)
              })
              break
            }
          }
          resolve(true)
        } catch (error) {
          resolve(true)
        }
      }
    })
  }

  async function userHasProAccount() {
    get(child(ref(firebaseDatabase), '/userHasPaid/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
      if (snapshot.val()) {
        setRedirect({code: 1})
      } else {
        checkTrialStatus()
      }
    })
  }

  onAuthStateChanged(firebaseAuth, () => {
    if (firebaseAuth.currentUser) {
      updatePaymentStatus().then(() => {userHasProAccount()})
    }
  })

  const payments = getStripePayments(firebaseApp, {
    productsCollection: "products",
    customersCollection: "customers",
  })

  function startPayment() {
    setIsLoading(true)
    createCheckoutSession(payments, {
      price: 'price_1LjWwIIdWgsbsOOIxU47ogt3',
      mode: 'payment',
    }).then((session) => {
      console.log(session)
      setRedirect({code: 2, url: session.url})
    })
  }

  if (redirect.code === 1) {
    return <Navigate to='/pro/home' />
  } else if (redirect.code === 2) {
    window.location.href = redirect.url
  } else {
    return (
      <main className='paywall-page-wrapper'>
        <div className='inner-paywall-wrapper'>
          <h1>Join the <a style={{color: "yellow"}} href="https://www.nytimes.com/games/wordle/index.html">Wordle</a> Clubhouse!</h1>

          <HeaderCTA setRedirect={setRedirect}/>

          <h2 className='list-header'>How it works:</h2>
          <ul className='benefits-ul'>
            <li className='benefit-item'>One time entry fee</li>
            <p className='benefit-item-desc'>Pay $3.99 today and never pay again. No monthly subscriptions. Completely ad-free experience.
                                             Your entry fee will help pay our website hosting costs.<TrialPeriodText setRedirect={setRedirect} trialIsAvailable={isTrialAvailable.status}/></p>

            <li className='benefit-item'>Sync all of your Wordle Stats to your account</li>
            <p className='benefit-item-desc'>You won't have to worry about losing your Wordle stats or playing on
                                            a different device because we will store all of your stats in the cloud,
                                            accessible from anywhere in the world.</p>
            
            <li className='benefit-item'>Follow other players to see their Wordle scores daily</li>
            <p className='benefit-item-desc'>Find your friends in the Wordle Clubhouse! Every day you will be able to
                                            access the daily feed -- a rotating wheel of posts that contain Wordle
                                            scores and guesses from the people you are following. Don't worry about spoilers: 
                                            you can only access the feed once you have verified your own Wordle solution.</p>

            <li className='benefit-item'>Guess Analysis</li>
            <p className='benefit-item-desc'>Have you ever wondered how many words were left after one of your guesses? Our advanced
                                             guess analysis system will show you exactly how many words were remaining after each one
                                             of your guesses, among many other things! We are also planning on significantly expanding
                                             our analysis features, so look out for those in the future.</p>

            <li className='benefit-item'>Clubhouse in beta</li>
            <p className='benefit-item-desc'>The Wordle Clubhouse is currently in beta. Expect exciting new features in the future!
                                            You will be able to be a part of the growth of the site until the final release.</p>
          </ul>

          {(isLoading) ? (
            <p style={{margin: "5px", marginBottom: "13px"}}>Loading checkout page, please wait...</p>
          ) : <p style={{margin: "5px", marginBottom: "13px"}}>&nbsp;</p>}
          <div className='paywall-cta-wrapper'>
            <CallToAction startPayment={startPayment}/>
            <TrialButton trialIsAvailable={isTrialAvailable.status} setRedirect={setRedirect} />
          </div>
          <Link to='/' style={{color: "whitesmoke", marginTop: "10px"}}>Not ready yet? Keep playing the free version.</Link>
        </div>
      </main>
    )
  }
}