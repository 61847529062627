import { genColors, getTodaysNumber, getTodaysWordle } from "./WordFrontend"
import { getAnswerList } from "./WordFunctions"
import { narrowAnswerList } from "./WordFrontend"
import { firebaseDatabase } from "../../Firebase"
import { get, ref, child, update } from 'firebase/database'

// cached version of the score map generated
// by the python wordle bot. this is done for
// optimization purposes
function getScoreMap() {
  let pos1 = new Map()
  pos1.set('s', 100.0)
  pos1.set('c', 53.8)
  pos1.set('b', 47.3)
  pos1.set('t', 40.7)
  pos1.set('p', 38.5)
  pos1.set('a', 38.3)
  pos1.set('f', 36.9)
  pos1.set('g', 31.4)
  pos1.set('d', 30.3)
  pos1.set('m', 29.2)
  pos1.set('r', 28.4)
  pos1.set('l', 23.8)
  pos1.set('w', 22.4)
  pos1.set('e', 19.7)
  pos1.set('h', 18.9)
  pos1.set('v', 11.7)
  pos1.set('o', 11.2)
  pos1.set('n', 10.1)
  pos1.set('i', 9.3)
  pos1.set('u', 9.0)
  pos1.set('q', 6.3)
  pos1.set('k', 5.5)
  pos1.set('j', 5.5)
  pos1.set('y', 1.6)
  pos1.set('z', 0.8)

  let pos2 = new Map()
  pos2.set('a', 100.0)
  pos2.set('o', 91.8)
  pos2.set('r', 87.8)
  pos2.set('e', 78.9)
  pos2.set('l', 66.1)
  pos2.set('i', 65.8)
  pos2.set('u', 60.9)
  pos2.set('h', 47.4)
  pos2.set('n', 28.6)
  pos2.set('t', 25.3)
  pos2.set('p', 20.1)
  pos2.set('w', 14.5)
  pos2.set('c', 13.2)
  pos2.set('m', 12.5)
  pos2.set('y', 7.2)
  pos2.set('d', 6.6)
  pos2.set('s', 5.3)
  pos2.set('b', 5.3)
  pos2.set('v', 4.9)
  pos2.set('x', 4.6)
  pos2.set('g', 3.6)
  pos2.set('k', 3.3)
  pos2.set('f', 2.6)
  pos2.set('q', 1.6)
  pos2.set('z', 0.7)
  pos2.set('j', 0.7)

  let pos3 = new Map()
  pos3.set('a', 100.0)
  pos3.set('i', 86.6)
  pos3.set('o', 79.2)
  pos3.set('e', 57.7)
  pos3.set('u', 53.7)
  pos3.set('r', 53.1)
  pos3.set('n', 44.6)
  pos3.set('l', 36.5)
  pos3.set('t', 36.2)
  pos3.set('s', 26.1)
  pos3.set('d', 24.4)
  pos3.set('g', 21.5)
  pos3.set('m', 19.9)
  pos3.set('p', 18.6)
  pos3.set('c', 18.2)
  pos3.set('b', 17.9)
  pos3.set('v', 16.0)
  pos3.set('y', 9.4)
  pos3.set('w', 8.5)
  pos3.set('f', 8.1)
  pos3.set('x', 3.9)
  pos3.set('k', 3.9)
  pos3.set('z', 3.6)
  pos3.set('h', 2.9)
  pos3.set('j', 1.0)
  pos3.set('q', 0.3)

  let pos4 = new Map()
  pos4.set('e', 100.0)
  pos4.set('n', 57.2)
  pos4.set('s', 53.8)
  pos4.set('l', 50.9)
  pos4.set('a', 50.6)
  pos4.set('i', 49.7)
  pos4.set('r', 47.2)
  pos4.set('c', 47.2)
  pos4.set('t', 43.7)
  pos4.set('o', 41.5)
  pos4.set('u', 25.5)
  pos4.set('g', 23.9)
  pos4.set('d', 21.7)
  pos4.set('m', 21.4)
  pos4.set('k', 17.3)
  pos4.set('p', 15.7)
  pos4.set('v', 14.5)
  pos4.set('f', 11.0)
  pos4.set('h', 8.8)
  pos4.set('w', 7.9)
  pos4.set('b', 7.5)
  pos4.set('z', 6.3)
  pos4.set('y', 0.9)
  pos4.set('x', 0.9)
  pos4.set('j', 0.6)

  let pos5 = new Map()
  pos5.set('e', 100.0)
  pos5.set('y', 86.1)
  pos5.set('t', 59.6)
  pos5.set('r', 49.9)
  pos5.set('l', 36.6)
  pos5.set('h', 32.4)
  pos5.set('n', 30.7)
  pos5.set('d', 27.9)
  pos5.set('k', 26.7)
  pos5.set('a', 14.9)
  pos5.set('o', 13.7)
  pos5.set('p', 13.2)
  pos5.set('m', 9.9)
  pos5.set('g', 9.7)
  pos5.set('s', 8.5)
  pos5.set('c', 7.3)
  pos5.set('f', 6.1)
  pos5.set('w', 4.0)
  pos5.set('b', 2.6)
  pos5.set('i', 2.6)
  pos5.set('x', 1.9)
  pos5.set('z', 0.9)
  pos5.set('u', 0.2)

  return [pos1, pos2, pos3, pos4, pos5]
}

function scoreWord(word, scoreMapList) {
  let score = 0

  for (let i=0; i<word.length; i++) {
    if (word.indexOf(word[i]) === i) {
      if (scoreMapList[i].has(word[i])) {
        score += scoreMapList[i].get(word[i])
      } else {
        score += 0
      }
    } else {
      score -= 20
    }
  }

  return score
}

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

// from https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
// function to get random number
/* eslint-disable */
function mulberry32(a) {
  return function() {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  }
}
/* eslint-enable */

export function getTodaysGuesses() {
  return getWordleGuesses(getTodaysWordle(), getTodaysNumber())
}

async function updateWordleDatabase(guessList) {
  return new Promise(async (resolve, reject) => {
    let snapshot = await get(child(ref(firebaseDatabase), "wordleVerifications/wordlebot"))

    if(snapshot.exists()) {
      if(snapshot.val().lastWordle < getTodaysNumber()) {

        snapshot = await get(child(ref(firebaseDatabase), "userTotals/wordlebot"))
        if (snapshot.exists()) {
          try {
            let currStats = snapshot.val()
            if (guessList.length === 1) {
              currStats.numOnes += 1
            } else if (guessList.length === 2) {
              currStats.numTwos += 1
            } else if (guessList.length === 3) {
              currStats.numThrees += 1
            } else if (guessList.length === 4) {
              currStats.numFours += 1
            } else if (guessList.length === 5) {
              currStats.numFives += 1
            } else if (guessList.length === 6 && guessList[guessList.length-1] === getTodaysWordle()) {
              currStats.numSixes += 1
            } else if (guessList.length === 6) {
              currStats.numFails += 1
            }

            currStats.numGuesses += guessList.length
            currStats.numPuzzles += 1

            let newStats = {
              numOnes: currStats.numOnes,
              numTwos: currStats.numTwos,
              numThrees: currStats.numThrees,
              numFours: currStats.numFours,
              numFives: currStats.numFives,
              numSixes: currStats.numSixes,
              numFails: currStats.numFails,
              numGuesses: currStats.numGuesses,
              numPuzzles: currStats.numPuzzles,
            }
            let newNumber = getTodaysNumber()

            const updates = {}
            updates['userTotals/wordlebot'] = newStats
            updates['wordleVerifications/wordlebot'] = {lastWordle: newNumber}

            update(ref(firebaseDatabase), updates).then(() => {
              resolve(true)
            })
          } catch (error) {
            reject(false)
          }
        }
      } else {
        resolve(true)
      }
    }

    resolve(true)
  })
}

export function getWordleGuesses(correctAnswer, num) {
  let scoreMapList = getScoreMap()
  let remainingAnswers = [...getAnswerList()]
  let randFunc = mulberry32(num)
  let guessList = []
  let colorList = []
  let phases = []

  remainingAnswers = remainingAnswers.sort((a,b) => {
    return scoreWord(b, scoreMapList) - scoreWord(a, scoreMapList)
  })

  phases.push(shuffle([...remainingAnswers]))

  let bestGuess = remainingAnswers[Math.floor(randFunc() * 31)]

  for (let j=0; j<6; j++) {
    let guessColors = genColors(bestGuess, correctAnswer)

    guessList.push(bestGuess)
    colorList.push(guessColors)

    if (bestGuess === correctAnswer) {
      updateWordleDatabase(guessList)
      return {guessList: guessList,
          phases: phases}
    }

    // narrow down answers
    remainingAnswers = narrowAnswerList(guessList, colorList, remainingAnswers)
    phases.push(shuffle([...remainingAnswers].slice(1)))
    bestGuess = remainingAnswers[0]
  }

  // update stats here
  updateWordleDatabase(guessList)

  return {guessList: guessList,
          phases: phases}
}