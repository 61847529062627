import './NewBarChart.css'

function BarDiv(props) {
  let adjLabel = props.width > 0 ? props.label : ''
  adjLabel = props.label !== 7 ? adjLabel : 'F'
  let scoreArr = ['#d38cff', 
                  '#d38cff',
                  '#8cff9a', 
                  '#58c454',
                  '#bccc52',
                  '#dbaf48',
                  '#db9748',
                  '#db6d48']
  if(props.width > 30) {
    return (
      <div className='bar-wrapper'>
        <p className='bar-label' style={{color: scoreArr[props.label]}}>{adjLabel}</p>
        <div className="bar" style={{width: props.width, backgroundColor: scoreArr[props.label]}}>
          <p className='inner-bar-val'>{props.width > 0 ? props.value : ''}</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className='bar-wrapper'>
        <p className='bar-label' style={{color: scoreArr[props.label]}}>{adjLabel}</p>
        <div className="bar" style={{width: props.width, backgroundColor: scoreArr[props.label]}} />
        <p className='outer-bar-val'>{props.width > 0 ? props.value : ''}</p>
      </div>
    )
  }
}

export default function NewBarChart(props) {
  let max = 0
  for (let i=0; i<props.barList.length; i++) {
    if (props.barList[i].value > max) {
      max = props.barList[i].value
    }
  }

  const FULL_WIDTH = document.body.clientWidth > 350 ? 300 : 200
  const SCALE_FACTOR = max / FULL_WIDTH

  return (
    <main className="bar-chart-wrapper" style={{width: FULL_WIDTH+25}}>
      {
        props.barList.map((bar, index) => <BarDiv key={index} value={bar.value} label={index+1} width={bar.value / SCALE_FACTOR}/>)
      }
    </main>
  )
}