import './SignupPage.css'
import { useState } from 'react'
import CallbackButton from '../components/buttons/CallbackButton'
import { firebaseAuth, firebaseDatabase } from '../Firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { Navigate, Link } from 'react-router-dom'
import { get, child, ref, update } from 'firebase/database'

async function validateUsername(username) {
  return new Promise((resolve, reject) => {
    get(child(ref(firebaseDatabase), 'usernameKeys/'+username)).then((snapshot) => {
      if (snapshot.exists()) {
        reject(false)
      }
      resolve(true)
    }).catch((error) => {
      reject(false)
    })
  })
}

async function addUsernameKeys(username, uid) {
  return new Promise(async (resolve, reject) => {
    try {
      await update(ref(firebaseDatabase, 'usernameKeys'), {
        [username]: uid
      })
      await update(ref(firebaseDatabase, 'keyUsernames'), {
        [uid]: username
      })
    } catch (error) {
      console.log("Error adding username keys:",error)
      reject(error)
    }

    resolve(true)
  })
}

export default function SignupPage() {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [errorText, setErrorText] = useState('')
  const [redirectStatus, setRedirectStatus] = useState(false)

  if (redirectStatus === false) {
    return (
      <main className="signup-container">
        <h2 className='signup-page-title'>Create your <Link to="/" style={{color: "yellow"}}>WordleBot</Link> account</h2>

        <form className='input-wrapper' onSubmit={(e) => {
          e.preventDefault()
          if (email.length === 0) {
            setErrorText('Error, invalid email.')
            return
          } else if (password.length === 0) {
            setErrorText('Error, invalid password.')
            return
          } else if (username.length === 0) {
            setErrorText('Error, username is too short.')
            return
          } else if (username.length > 16) {
            setErrorText('Error, username must be 16 characters or less.')
            return
          } else if (username.replaceAll(' ', '').toLowerCase() === 'wordlebot' || username.replaceAll(' ', '').toLowerCase() === 'wordlebot(hardmode)') {
            setErrorText('Error, that username is reserved for the WordleBot.')
            return
          }

          validateUsername(username).then(() => {
            createUserWithEmailAndPassword(firebaseAuth, email, password).then(() => {
              signInWithEmailAndPassword(firebaseAuth, email, password).then(() => {
                updateProfile(firebaseAuth.currentUser, {
                  displayName: username,
                }).then(() => {
                  addUsernameKeys(username.toLowerCase(), firebaseAuth.currentUser.uid).then(() => {
                    update(ref(firebaseDatabase, 'userTrialTracker'), {
                      [firebaseAuth.currentUser.uid]: Date.now()+1_209_600_000 // number milliseconds in two weeks
                    }).then(() => {
                      setRedirectStatus(true)
                    }).catch(() => {
                      setErrorText("Error, trial period was not able to start. Try refreshing the site.")
                    })
                  }).catch(() => {
                    setErrorText("Error, account only partially created. Please contact support with code #1111.")
                  })
                }).catch((error) => {
                  setErrorText('Server error (' + error + ') occured, please try again later.')
                })

                // do other updates
              }).catch((error) => {
                setErrorText('Server error (' + error + ') occured, please try again later.')
              })
            }).catch((error) => {
              if (error.code === 'auth/email-already-in-use') {
                setErrorText('Error, that email address is already taken.')
              } else if (error.code === 'auth/weak-password') {
                setErrorText('Error, password must be at least six characters.')
              } else {
                setErrorText('Server error (' + error + ') occured, please try again later.')
              }
            })
          }).catch(() => {
            setErrorText('Error, username already taken.')
          })
        }}>
          <div className='inner-input-wrapper'>
            <label className='signup-label' htmlFor='email'>Email Address</label>
            <input type='text' className='email-box signup-input-box' name='email' onInput={e => setEmail(e.target.value)}/>
          </div>

          <div className='inner-input-wrapper'>
            <label className='signup-label' htmlFor='username'>Username</label>
            <input type='text' className='signup-input-box' name='username' onInput={e => setUsername(e.target.value)}/>
          </div>

          <div className='password-boxes'>
            <div className='inner-input-wrapper'>
              <label className='signup-label' htmlFor='password'>Password</label>
              <input type='password' className='small-input-box signup-input-box' name='password' onBlur={
                () => {
                  if (password !== confirmPass && confirmPass !== '') {
                    setErrorText("Sorry, passwords do not match.")
                  } else if (password.length < 6 && password !== '') {
                    setErrorText('Your password must be at least six characters.')
                  } else {
                    setErrorText('')
                  }
                }
              } onInput={e => setPassword(e.target.value)}/>
            </div>

            <div className='inner-input-wrapper'>
              <label className='signup-label' htmlFor='confirmPass'>Confirm</label>
              <input type='password' className='small-input-box signup-input-box' name='confirmPass' onBlur={
                () => {
                  if (password !== confirmPass) {
                    setErrorText("Sorry, passwords do not match.")
                  } else {
                    setErrorText('')
                  }
                }
              } onInput={e => setConfirmPass(e.target.value)}/>
            </div>
          </div>

          <p className='submit-error-text'>{errorText}&nbsp;</p>

          <div style={{margin: '5px'}}>
            <CallbackButton innerText='Submit' />
          </div>

          <Link style={{marginTop: "10px", color: "white"}} to="/login">Already have an account? Log in here.</Link>
        </form>
      </main>
    )
  } else {
    return (
      <Navigate to={{pathname: "/pro/home"}} />
    )
  }
}
