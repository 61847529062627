import React from 'react'
import './BotShowcase.css'
import WordleBox from './WordleBox'

import { getTodaysWordle,
         genTodaysColors } from '../helper_functions/WordFrontend'
import { getTodaysGuesses } from '../helper_functions/WordleBot'

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

// msg.text must be an array of length 3
function BotTerminal(props) {
  return (
    <div className='bot-terminal'>
      <div className='outer-title-wrapper'>
        <div className='terminal-title'>
          <p className='terminal-title-text'>{props.title}</p>
        </div>
      </div>
      <div className='terminal-body'>
        <p className='terminal-body-text'>{'$>'} {props.msg.text[0]}</p>
        <p className='terminal-body-text'>&nbsp;&nbsp;&nbsp;{props.msg.text[1]}</p>
        <p className='terminal-body-text'>&nbsp;&nbsp;&nbsp;{props.msg.text[2]}</p>
      </div>
    </div>
  )
}

class BotShowcase extends React.Component {
  constructor(props) {
    super(props)

    let boxes = []
    // fill active box
    boxes.push({
      letter: ' ',
      box_class: "box box-active",
    })

    // fill rest of the boxes
    for (let i=1; i<30; i++) {
      boxes.push({
        letter: ' ',
        box_class: "box box-inactive",
      })
    }

    this.todaysWordle = getTodaysWordle()
    this.state = {boxes: boxes, active_box: 0, analyzeBoxList: ['', '', ''], animationComplete: false}
    this.fillGuess = this.fillGuess.bind(this)
    this.fillLetter = this.fillLetter.bind(this)
    this.getLastRowWord = this.getLastRowWord.bind(this)
    this.updateLastRowColors = this.updateLastRowColors.bind(this)
    this.analyzeGuessUI = this.analyzeGuessUI.bind(this)
  } 

  getLastRowWord() {
    if (this.state.active_box % 5 !== 0 || this.state.active_box === 0) {
        console.log("BotShowcase::getLastRowWord -> Error: last row not complete:", this.state)
        return
    }
    let returnStr = ""

    for (let i=this.state.active_box-5; i<this.state.active_box; i++) {
      returnStr += this.state.boxes[i].letter
    }

    return returnStr
  }

  updateLastRowColors() {
    this.setState((prevState) => {
      let new_boxes = prevState.boxes

      if (prevState.active_box % 5 !== 0 || this.state.active_box === 0) {
        console.log("BotShowcase::updateLastRowColors -> Error: last row not complete:", prevState)
        return
      }

      let lastWord = this.getLastRowWord()
      let colorStr = genTodaysColors(lastWord)

      let offsetI = prevState.active_box-5
      for (let i=prevState.active_box-5; i<prevState.active_box; i++) {
        if (colorStr[i-offsetI] === 'g') {
          new_boxes[i].box_class = 'box box-filled-green'
        } else if (colorStr[i-offsetI] === 'y') {
          new_boxes[i].box_class = 'box box-filled-yellow'
        } else if (colorStr[i-offsetI] === 'b') {
          new_boxes[i].box_class = 'box box-filled-black'
        }
      }

      return {boxes: new_boxes}
    })
  }

  fillLetter(letterToFill) {
    this.setState((prevState) => {
      let new_boxes = prevState.boxes
      new_boxes[prevState.active_box].letter = letterToFill
      new_boxes[prevState.active_box].box_class = "box box-filled"

      if (prevState.active_box < 29) {
        new_boxes[prevState.active_box+1].box_class = "box box-active"
      }

      return {boxes: new_boxes, active_box: prevState.active_box + 1}
    })
  }

  fillGuess(guess, guessesList) {
    if (guess.length !== 5) {
      console.log("BotShowcase::fillGuess -> Error: provided guess '" + guess + "' is not 5 letters.")
      return
    }

    for (let i=0; i<5; i++) {
      delay((i+1)*350).then(() => this.fillLetter(guess[i]))
    }

    if (guessesList[guessesList.length-1] === guess && guessesList.length !== 6) {
      delay(5*350).then(() => {
        this.setState((prevState) => {
          let new_boxes = prevState.boxes
          new_boxes[prevState.active_box].box_class = 'box box-inactive'
          return {boxes: new_boxes, animationComplete: true}
        })
      })
    }
  }

  analyzeGuessUI(todaysGame, phase) {
    console.log(todaysGame.phases, phase)
    let delayAmt = Math.min(3000 / todaysGame.phases[phase].length, 500)
    let totalDelay = delayAmt * todaysGame.phases[phase].length

    console.log(delayAmt, totalDelay, todaysGame.phases[phase].length)

    for(let i=0; i<todaysGame.phases[phase].length; i++) {
      let j = i
      delay(delayAmt*j).then(() => {
        this.setState((prevState) => {
          return {analyzeBoxList: ['Running word list bisection...', 
                                   (j+1) + '/' + (todaysGame.phases[phase].length+1) + ' (' + Math.round((j/(todaysGame.phases[phase].length+1)*100)) + '% complete)',
                                   'Analyzing word: ' + todaysGame.phases[phase][j]]}
        })
      })
    }

    let i = todaysGame.phases[phase].length
    delay(totalDelay + delayAmt).then(() => {
      this.setState((prevState) => {
        return {analyzeBoxList: ['Running word list bisection...', 
                                  (i+1) + '/' + (todaysGame.phases[phase].length+1) + ' (' + Math.round(((i+1)/(todaysGame.phases[phase].length+1))*100) + '% complete)',
                                  'Analyzing word: ' + todaysGame.guessList[phase]]}
      })
    })
  }

  componentDidMount() {
    let todaysGame = getTodaysGuesses()
    let guesses = todaysGame.guessList
    console.log(todaysGame)
    let i = 0


    // guess animation
    const analysisTime = 4000
    const guessInputTime = 2500
    const colorShowTime = 500
    const cycleTime = analysisTime + guessInputTime + colorShowTime

    // repeats:
    // first 4000 ms (analyze guess)
    // second 2500 ms (fill in letters)
    // last 500 ms (fill in colors)
    guesses.forEach(element => {
      delay(((i) * cycleTime) + analysisTime).then(() => this.fillGuess(element, guesses))
      delay(((i) * cycleTime) + analysisTime + guessInputTime).then(() => this.updateLastRowColors())
      i += 1
    });

    for (let i=0; i<guesses.length; i++) {
      delay(i * cycleTime).then(() => this.analyzeGuessUI(todaysGame, i))
    }
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <main className='guess-input-main'>
          <WordleBox boxes={this.state.boxes} />
          <BotTerminal title="/wordlebot.exe" msg={{
            text: this.state.analyzeBoxList
          }} />
      </main>
    );
  }
}

export default BotShowcase;