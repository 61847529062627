import { get, update, child, ref } from 'firebase/database'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ProfileStats from '../../components/dataviz/ProfileStats'
import { firebaseAuth, firebaseDatabase } from '../../Firebase'
import './ProfilePage.css'
import FuzzySearch from 'fuzzy-search'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import CallbackButton from '../../components/buttons/CallbackButton'

function ProfileToggle(props) {
  const [isFriend, setIsFriend] = useState(0)
    if (isFriend === 0) {
      get(child(ref(firebaseDatabase), 'IdFollowsUsers/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val()[props.uid] === true) {
            setIsFriend(true)
          }
        }
      }).catch((error) => {
        console.log("Error retrieving friends list:", error)
      })
    }

  return (
    <div className='profile-toggle-wrapper' onClick={() => {toggleFriend(props.uid, props.onFinishedCallback); setIsFriend(!isFriend)}}>
      <div className={'toggle-checkbox-' + (isFriend ? 'enabled' : 'disabled')} />
      <p className={'username-box-' + (isFriend ? 'enabled' : 'disabled')}>{props.username}</p>
    </div>
  )
}

function FollowLists (props) {
  return (
    <div className='following-lists-wrapper'>
      <div className='user-follow-list'>
        <h2>Following {props.userFollowsList.length}</h2>
        <li className='username-list'>
          {props.userFollowsList.map((value, index) => {
            return <ul key={index}><Link className='username-link' to={'/pro/profile/'+value.name}>{value.name}</Link></ul>
          })}
        </li>
      </div>
      <span className='section-divider' />
      <div className='user-follow-list'>
        <h2>Followed by {props.userFollowedBy.length}</h2>
        <li className='username-list'>
          {props.userFollowedBy.map((value, index) => {
            return <ul key={index}><Link className='username-link' to={'/pro/profile/'+value.name}>{value.name}</Link></ul>
          })}
        </li>
      </div>
    </div>
  )
}

function addFriend(uid) {
  update(ref(firebaseDatabase, 'IdFollowsUsers/' + firebaseAuth.currentUser.uid), {
    [uid]: true
  }).then(() => {
  }).catch((error) => {
    console.log("Error updating friends: " + error)
  })

  update(ref(firebaseDatabase, 'usersFollowID/' + uid), {
    [firebaseAuth.currentUser.uid]: true
  }).then(() => {
  }).catch((error) => {
    console.log("Error updating friends (2): " + error)
  })
}

function removeFriend(uid) {
  update(ref(firebaseDatabase, 'IdFollowsUsers/' + firebaseAuth.currentUser.uid), {
    [uid]: false
  }).then(() => {
  }).catch((error) => {
    console.log("Error updating friends: " + error)
  })

  update(ref(firebaseDatabase, 'usersFollowID/' + uid), {
    [firebaseAuth.currentUser.uid]: false
  }).then(() => {
  }).catch((error) => {
    console.log("Error updating friends (2): " + error)
  })
}

function toggleFriend(uid, onFinishedCallback) {
  get(child(ref(firebaseDatabase), 'IdFollowsUsers/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
    if (snapshot.exists()) {
      if (snapshot.val()[uid] === true) {
        removeFriend(uid)
        onFinishedCallback()
      } else {
        addFriend(uid)
        onFinishedCallback()
      }
    } else {
      addFriend(uid)
      onFinishedCallback()
    }
  }).catch((error) => {
    console.log("Error retrieving friends list:", error)
  })
}

function ProfileSearchResults (props) {
  const [usernameList, setUsernameList] = useState(null)
  let keys = null
  let results = null

  if (!usernameList) {
    get(child(ref(firebaseDatabase), 'usernameKeys')).then((snapshot) => {
      if (snapshot.exists()) {
        setUsernameList(snapshot.val())
      }
    })
  } else {
    keys = Object.keys(usernameList)
    const searcher = new FuzzySearch(keys, [], {caseSensitive: false, sort: true})
    results = searcher.search(props.query).slice(0, 3)
  }

  // eslint-disable-next-line
  if ((results ? results.length>0 : false) && props.query != "") {
    return (
      <ul className='username-results-list'>
        {
          results.map((value, index) => {
            if (usernameList[value] !== firebaseAuth.currentUser.uid) {
              return (
                <li className='username-result' key={index}><ProfileToggle onFinishedCallback={props.onFinishedCallback} username={value} uid={usernameList[value]} /></li>
              )
            } else if (results.length === 1) {
              return (
                <p key={index} style={{margin: '10px'}}>&nbsp;</p>
              )
            }

            return null
          })
        }
      </ul>
    )
  } else {
    return (
      <p style={{margin: '10px'}}>&nbsp;</p>
    )
  }
}

function FollowInputBox (props) {
  const [searchQuery, setSearchQuery] = useState('')

  if (props.isActive) {
    return (
      <div className='follow-input-wrapper'>
        <CallbackButton innerText="Invite a Friend" onClick={() => {
          if (navigator.share) {
            navigator.share({
              title: document.title,
              text: "Come check out the Wordle Clubhouse!",
              url: "/"
            })
            .then(() => {
            })
            .catch(error => {
              navigator.clipboard.writeText("playwordlebot.com")
              props.setClipboard(true)
              setTimeout(() => {
                props.setClipboard(false)
              }, 2000)
            });
          }
        }} />

        <h2 style={{marginTop: "12px"}}>Follow/Unfollow User</h2>
        <form onSubmit={(e) => {
            e.preventDefault()
          }} onChange={(e) => {
            setSearchQuery(e.target.value)
          }}>

          <input className='follow-input-box' placeholder='enter username...'/>
        </form>

        <ProfileSearchResults onFinishedCallback={props.onFinishedCallback} query={searchQuery}/>
      </div>
    )
  }
}

function HorizontalDivider (props) {
  return (
    <div className='horizontal-divider' />
  )
}

async function getUserDetails (username) {
  return new Promise(async (resolve, reject) => {
    let userDetails = {uid: null, userFollowedBy: null, userFollowsList: null}
    let snapshot = await get(child(ref(firebaseDatabase), 'usernameKeys/' + username))
    if (snapshot.exists()) {
      userDetails.uid = snapshot.val()
    } else {
      reject(false)
    }

    snapshot = await get(child(ref(firebaseDatabase), 'keyUsernames'))
    if (snapshot.exists()) {
      let keyUserMap = snapshot.val()
      snapshot = await get(child(ref(firebaseDatabase), 'IdFollowsUsers/'+userDetails.uid))
      if (snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        let newArr = []

        keys.forEach((value, ind) => {
          if (snapshot.val()[value] === true) {
            newArr.push({name: keyUserMap[value], uid: value})
          }
        })

        userDetails.userFollowsList = newArr 
      } else {
        userDetails.userFollowsList = []
      }
    }

    snapshot = await get(child(ref(firebaseDatabase), 'keyUsernames'))
    if (snapshot.exists()) {
      let keyUserMap = snapshot.val()
      snapshot = await get(child(ref(firebaseDatabase), 'usersFollowID/'+userDetails.uid))
      if (snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        let newArr = []
        keys.forEach((value, ind) => {
          if (snapshot.val()[value] === true) {
            newArr.push({name: keyUserMap[value], uid: value})
          }
        })
        userDetails.userFollowedBy = newArr
      } else {
        userDetails.userFollowedBy = []
      }
    } else {
      userDetails.userFollowedBy = []
    }

    resolve(userDetails)
  })
}

export default function ProfilePage (props) {
  let {username} = useParams()
  let displayUsername = username === 'wordlebot' ? 'WordleBot' : username
  const [invalidUsername, setInvalidUsername] = useState(false)
  const [clipboard, setClipboard] = useState(false)

  const [userDetails, setUserDetails] = useState(null)

  function onFinishedCallback() {
    let newDetails = {
      ...userDetails
    }
    newDetails.outOfDate = true
    setUserDetails(newDetails)
  }

  useEffect(() => {
    if (userDetails) {
      if (userDetails.username !== username || userDetails.outOfDate) {
        getUserDetails(username).then((data) => {
          if (data) {
            data.username = username
            data.outOfDate = false
            setUserDetails(data)
          } else {
            setInvalidUsername(true)
          }
        })
      }
    } else {
      getUserDetails(username).then((data) => {
        if (data) {
          data.username = username
          data.outOfDate = false
          setUserDetails(data)
        } else {
          setInvalidUsername(true)
        }
      })
    }
  })

  if (!invalidUsername && userDetails !== null) {
    return (
      <main className='profile-page-wrapper'>
        <div className={clipboard ? 'copied-to-clipboard-visible':'copied-to-clipboard-hidden'}>
          <p className='notification-text'>Link copied to clipboard!</p>
        </div>
        <div className='profile-title-wrapper'>
          <h1 style={{margin: '0px', marginTop: '15px', marginBottom: '5px'}}>{displayUsername}</h1>
        </div>
        <Link style={{color: 'lightblue'}} to='/pro/home'>Go back to my Daily Feed.</Link>
        <ProfileStats uid={userDetails.uid} />
        <FollowInputBox onFinishedCallback={onFinishedCallback} setClipboard={setClipboard} isActive={ firebaseAuth.currentUser ? firebaseAuth.currentUser.uid === userDetails.uid : false } />
        {username === 'wordlebot' ? null :
          <div className='follow-lists-div-wrapper'>
            <HorizontalDivider />
            <FollowLists userFollowsList={userDetails.userFollowsList} userFollowedBy={userDetails.userFollowedBy} />
          </div>
        }
      </main>
    )
  } else if (!userDetails && !invalidUsername) {
    return (
      <h2>Loading...</h2>
    )
  } else {
    return (
      <h2>Sorry, the profile for '{username}' does not currently exist.</h2>
    )
  }
}
/*let numFails = 0
  let numFives = 0
  let numFours = 0
  let numGuesses = 0
  let numOnes = 0
  let numPuzzles = 0
  let numSixes = 0
  let numThrees = 0
  let numTwos = 0

  for (let i=0; i<=getTodaysNumber()-1; i++) {
      let answer = getSpecificWordle(i)
      let guesses = getWordleGuesses(answer, i).guessList

      numPuzzles += 1
      numGuesses += guesses.length
      if (guesses.length === 1) {
          numOnes += 1
      } else if (guesses.length === 2) {
          numTwos += 1
      } else if (guesses.length === 3) {
          numThrees += 1
      } else if (guesses.length === 4) {
          numFours += 1
      } else if (guesses.length === 5) {
          numFives += 1
      } else if (guesses.length === 6 && guesses[guesses.length-1] === answer) {
          numSixes += 1
      } else {
          numFails += 1
      }
      
      console.log("Processed #" + i + ":", answer)
  }

  console.log("numFails:", numFails)
  console.log("numFives:", numFives)
  console.log("numFours:", numFours)
  console.log("numGuesses:", numGuesses)
  console.log("numOnes:", numOnes)
  console.log("numPuzzles:", numPuzzles)
  console.log("numSixes:", numSixes)
  console.log("numThrees:", numThrees)
  console.log("numTwos:", numTwos)*/

