import './LoginPage.css'
import { useState } from 'react'
import CallbackButton from '../components/buttons/CallbackButton'
import { firebaseAuth } from '../Firebase'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { Navigate, Link } from 'react-router-dom'

export default function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  const [statusText, setStatusText] = useState(false)
  const [redirectStatus, setRedirectStatus] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)

  if (redirectStatus === false) {
    return (
      <main className="signup-container">
        <h2 className='signup-page-title'>Login to <Link to="/" style={{color: "yellow"}}>WordleBot</Link></h2>

        {resetPassword ? '' :(
          <form className='input-wrapper' onSubmit={(e) => {
                e.preventDefault()
                if (email.length === 0) {
                  setErrorText("Error, invalid email.")
                  return
                }
                if (password.length === 0) {
                  setErrorText("Error, invalid password.")
                  return
                }
                signInWithEmailAndPassword(firebaseAuth, email, password).then(() => {
                  setRedirectStatus(true)
                }).catch((error) => {
                  if (error.code === 'auth/wrong-password') {
                    setErrorText("Error, that's the wrong password.")
                  } else if (error.code === 'auth/invalid-email') {
                    setErrorText("Error, invalid email.")
                  } else if (error.code === 'auth/user-not-found') {
                    setErrorText("Error, there is no user with that email.")
                  } else if (error.code === 'auth/too-many-requests') {
                    setErrorText("Access to this account has been disabled due to too many failed login attempts. Please try again in a couple minutes.")
                  } else {
                    setErrorText("(Server Error) " + error)
                  }
                })
              }}>
            <div className='inner-input-wrapper'>
              <label className='signup-label' htmlFor='email'>Email Address</label>
              <input type='text' className='email-box signup-input-box' name='email' onInput={e => setEmail(e.target.value)}/>
            </div>

            <div className='inner-input-wrapper'>
              <label className='signup-label' htmlFor='password'>Password</label>
              <input type='password' className='signup-input-box' name='password' onInput={e => setPassword(e.target.value)}/>
            </div>

            <p className='submit-error-text'>{errorText}&nbsp;</p>

            <div className='button-wrapper' style={{margin: '5px'}}>
              <CallbackButton innerText='Submit' width="150px" />
            </div>

            <Link style={{color: "white", marginTop: "10px"}} to="/signup">Need an account? Sign up here.</Link>
          </form>
        )}

        {resetPassword ? 
          (<form className='input-wrapper' onSubmit={(e) => {
              e.preventDefault()
              sendPasswordResetEmail(firebaseAuth, email).then(() => {
                setStatusText("Email sent successfully! Remember to check your spam folder.")
              }).catch((error) => {
                setStatusText(error)
              })
            }}>
          <div className='inner-input-wrapper'>
            <label className='signup-label' htmlFor='email'>Email of Account</label>
            <input type='text' className='email-box signup-input-box' name='email' onInput={e => setEmail(e.target.value)}/>
          </div>

          <p className='submit-status-text'>{statusText}&nbsp;</p>

          <div className='button-wrapper' style={{margin: '5px'}}>
            <CallbackButton innerText='Submit' width="150px" />
          </div>
        </form>)
        :
        ( <p className='hover-link' style={{color: "pink"}} onClick={() => {
          setResetPassword(true)
        }}>Need a password reset email? Click here.</p> )
        }
      </main>
    )
  } else {
    return (
      <Navigate to={{pathname: "/pro/home"}} />
    )
  }
}
