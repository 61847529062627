import React from 'react'
import './PaidEnterGuesses.css'
import GuessInput from '../../components/wordlebox/GuessInput'
import { setToVerified } from '../../features/verifiedSlice'
import { useSelector, useDispatch } from 'react-redux'
import { firebaseAuth, firebaseDatabase } from '../../Firebase'
import { Navigate } from 'react-router-dom'
import { ref, set, child, get, update } from 'firebase/database'
import { getTodaysNumber, getTodaysWordle } from '../../components/helper_functions/WordFrontend'

export default function PaidEnterGuesses() {
  let isVerified = useSelector((state) => state.verified.value)
  const dispatch = useDispatch()
  if (firebaseAuth.currentUser) {
    get(child(ref(firebaseDatabase), 'wordleVerifications/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
      if(snapshot.exists()) {
        let currDate = new Date()
        currDate.setHours(0, 0, 0, 0)
        if(snapshot.val().lastWordle > currDate.getTime()) {
          dispatch(setToVerified())
          return (
            <Navigate to='/pro/view-wordle-bot' />
          )
        }
      }
    }).catch((error) => {
      console.log("error retrieving last verification: " + error)
    })
  }

  async function safeVerifyUser(guesses) {
    return new Promise(async (resolve, reject) => {
      // Make sure we have not already submitted guesses today
      let snapshot = await get(child(ref(firebaseDatabase), 'wordleVerifications/' + firebaseAuth.currentUser.uid))
      if(snapshot.exists()) {
        let currDate = new Date()
        currDate.setHours(0, 0, 0, 0)
        if(snapshot.val().lastWordle > currDate.getTime()) {
          resolve(true)
        }
      }

        // Update the users wordle verification
      await set(ref(firebaseDatabase, 'wordleVerifications/' + firebaseAuth.currentUser.uid), {
        lastWordle: Date.now()
      })

      // Format guess string for database
      const todaysNum = getTodaysNumber()
      let guessStr = ""
      guesses.forEach((guess, index) => {
        guessStr += guess
        if (index !== guesses.length-1) {
          guessStr += "-"
        }
      })

      // Save the guesses in the database
      await update(ref(firebaseDatabase, 'userGuesses/' + firebaseAuth.currentUser.uid), {
        [todaysNum]: guessStr
      })

      // Format guess stat data for database
      let gatheredData = {
        numOnes: guesses.length === 1 ? 1 : 0,
        numTwos: guesses.length === 2 ? 1 : 0,
        numThrees: guesses.length === 3 ? 1 : 0,
        numFours: guesses.length === 4 ? 1 : 0,
        numFives: guesses.length === 5 ? 1 : 0,
        numSixes: (guesses.length === 6 && guesses[guesses.length-1] === getTodaysWordle()) ? 1 : 0,
        numFails: (guesses[guesses.length-1] !== getTodaysWordle()) ? 1 : 0,
        numPuzzles: 1,
        numGuesses: guesses.length,
      }

        // Update the wordle stats in the database
      snapshot = await get(child(ref(firebaseDatabase), 'userTotals/' + firebaseAuth.currentUser.uid))

      let newData = {
        numOnes: 0,
        numTwos: 0,
        numThrees: 0,
        numFours: 0,
        numFives: 0,
        numSixes: 0,
        numFails: 0,
        numPuzzles: 0,
        numGuesses: 0,
      }

      if (snapshot.exists()) {
        let oldData = snapshot.val()
        newData = oldData
      }

      newData.numOnes += gatheredData.numOnes
      newData.numTwos += gatheredData.numTwos
      newData.numThrees += gatheredData.numThrees
      newData.numFours += gatheredData.numFours
      newData.numFives += gatheredData.numFives
      newData.numSixes += gatheredData.numSixes
      newData.numFails += gatheredData.numFails
      newData.numPuzzles += gatheredData.numPuzzles
      newData.numGuesses += gatheredData.numGuesses

      await set(ref(firebaseDatabase, 'userTotals/' + firebaseAuth.currentUser.uid), newData)

      resolve(true)
    })
  }

  function updateUserVerification(guesses) {
    console.log("Updating verification status for", firebaseAuth.currentUser.displayName)
    get(child(ref(firebaseDatabase), 'wordleVerifications/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
      if(snapshot.exists()) {
        let currDate = new Date()
        currDate.setHours(0, 0, 0, 0)
        if(snapshot.val().lastWordle > currDate.getTime()) {
          dispatch(setToVerified())
          console.log("Skipping guess submit because Wordle was already submitted today.")
          return
        }
      }

      // Update the users wordle verification
      set(ref(firebaseDatabase, 'wordleVerifications/' + firebaseAuth.currentUser.uid), {
        lastWordle: Date.now()
      }).then(() => {
        // Success
        console.log("Set verification timestamp for", firebaseAuth.currentUser.displayName)
      }).catch((error) => {
        console.log("Error updating: " + error)
      })

      const todaysNum = getTodaysNumber()
      let guessStr = ""
      guesses.forEach((guess, index) => {
        guessStr += guess
        if (index !== guesses.length-1) {
          guessStr += "-"
        }
      })

      // Save the guesses in the database
      update(ref(firebaseDatabase, 'userGuesses/' + firebaseAuth.currentUser.uid), {
        [todaysNum]: guessStr
      }).then(() => {
        console.log("saved guesses to database")
      }).catch((error) => {
        console.log("Error updating guesses: " + error)
      })

      console.log(guesses)

      let gatheredData = {
        numOnes: guesses.length === 1 ? 1 : 0,
        numTwos: guesses.length === 2 ? 1 : 0,
        numThrees: guesses.length === 3 ? 1 : 0,
        numFours: guesses.length === 4 ? 1 : 0,
        numFives: guesses.length === 5 ? 1 : 0,
        numSixes: (guesses.length === 6 && guesses[guesses.length-1] === getTodaysWordle()) ? 1 : 0,
        numFails: (guesses[guesses.length-1] !== getTodaysWordle()) ? 1 : 0,
        numPuzzles: 1,
        numGuesses: guesses.length,
      }
      // Update the wordle stats in the database
      get(child(ref(firebaseDatabase), 'userTotals/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
        let newData = {
          numOnes: 0,
          numTwos: 0,
          numThrees: 0,
          numFours: 0,
          numFives: 0,
          numSixes: 0,
          numFails: 0,
          numPuzzles: 0,
          numGuesses: 0,
        }

        if (snapshot.exists()) {
          let oldData = snapshot.val()
          newData = oldData
        }

        newData.numOnes += gatheredData.numOnes
        newData.numTwos += gatheredData.numTwos
        newData.numThrees += gatheredData.numThrees
        newData.numFours += gatheredData.numFours
        newData.numFives += gatheredData.numFives
        newData.numSixes += gatheredData.numSixes
        newData.numFails += gatheredData.numFails
        newData.numPuzzles += gatheredData.numPuzzles
        newData.numGuesses += gatheredData.numGuesses

        set(ref(firebaseDatabase, 'userTotals/' + firebaseAuth.currentUser.uid), newData).then(() => {
          //success
        }).catch((error) => {
          console.log("firebase stats error", error)
        })
      })
    }).catch((error) => {
      console.log("error retrieving last verification: " + error)
    })
  }
  
  function verifyUserInState(guesses) {
    safeVerifyUser(guesses).then(() => {
      dispatch(setToVerified())
    }).catch((error) => {
      alert("Submission error, please try again. Details:", error)
    })
  }

  console.log("Todays:", getTodaysNumber(), getTodaysWordle())

  if (!isVerified) {
    return (
      <main>
        <div className="main-guess-box">
          <h2 className="guesses-header"> Verify Today's Solution </h2>

          <div className="enter-guesses">
            <GuessInput isVerified={isVerified} verifyCallback={verifyUserInState}/>
          </div>
        </div>
      </main>
    );
  } else {
    return (
      <Navigate to='/pro/view-wordle-bot' />
    )
  }
}
