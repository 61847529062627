import './WordleBox.css'
function Box(props) {
  return (
    <span className={props.box_class}> 
      <p className="wordle-letter unselectable"> {props.letter.toUpperCase()} </p>
    </span>
  );
}

function Row(props) {
  let boxes = props.boxes.map((box) => <Box key={props.boxes.indexOf(box).toString()} box_class={ box.box_class } letter={ box.letter} />)
  return (
    <div className="row-container">
      { boxes }
    </div>
  );
}

export default function WordleBox(props) {
  let rows = []
  let numRows = 6

  let filledLength = 0
  for (let i=0; i<props.boxes.length; i++) {
    if (props.boxes[i].letter !== ' ') {
      filledLength += 1
    } else {
      break
    }
  }

  if(props.minimalView) {
    numRows = Math.floor(filledLength / 5)
  } 

  for (let i=0; i<numRows; i++) {
    rows.push(
      <Row key={i} boxes={props.boxes.slice(i*5, (i*5)+5)} />
    )
  }

  return (
    <div className='boxes-container'>
      {rows}
    </div>
  )
}
/*
      <Row boxes={props.boxes.slice(0, 5)} />
      <Row boxes={props.boxes.slice(5, 10)} />
      <Row boxes={props.boxes.slice(10, 15)} />
      <Row boxes={props.boxes.slice(15, 20)} />
      <Row boxes={props.boxes.slice(20, 25)} />
      <Row boxes={props.boxes.slice(25, 30)} />
*/