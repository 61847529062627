import React from 'react'
import './EnterGuesses.css'
import GuessInput from '../../components/wordlebox/GuessInput'
import { setToVerified } from '../../features/verifiedSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'

export default function FreeEnterGuesses() {
  let isVerified = useSelector((state) => state.verified.value)
  const dispatch = useDispatch()

  function verifyUserInState(guesses) {
    dispatch(setToVerified())
  }

  if (!isVerified) {
    return (
      <main>
        <div className="main-guess-box">
          <h2 className="guesses-header"> Verify Today's Solution </h2>

          <div className="enter-guesses">
            <GuessInput isVerified={isVerified} verifyCallback={verifyUserInState}/>
          </div>
        </div>
      </main>
    );
  } else {
    return (
      <Navigate to='/basic/view-wordle-bot' />
    )
  }
}

/*
        <nav>
          <Link to='/basic/view-wordle-bot'>View Wordle Bot</Link> |{" "}
          <Link to='/'>Go Home</Link>
        </nav>
*/
