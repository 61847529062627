import React from 'react'
import './Keyboard.css'

function Letter(props) {
  return (
    <div className={"letter-wrapper"+props.row} onTouchStart={props.onClick}>
      <div className="inner-letter-wrapper">
        <p className="keyboard-key unselectable"> {props.key_val.toUpperCase()} </p>
      </div>
    </div>
  )
}

class Keyboard extends React.Component {
  constructor(props) {
    super(props)

    this.handleKeypress = this.handleKeypress.bind(this)
    this.pressKey = this.pressKey.bind(this)
  } 

  handleKeypress(event) {
    let keyChar = event.target.innerText.toLowerCase()
    this.props.onPress(keyChar)
  }

  pressKey(keyChar) {
    this.props.onPress(keyChar)
  }

  render() {
    return (
      <div className="keyboard-wrapper">
        <div className="inner-wrapper">
          <div className="key-row">
            <Letter row="1" key_val="q" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="w" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="e" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="r" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="t" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="y" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="u" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="i" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="o" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="1" key_val="p" onClick={this.handleKeypress} pressKey={this.pressKey}/>
          </div>

          <div className="key-row">
            <Letter row="2" key_val="a" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="s" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="d" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="f" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="g" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="h" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="j" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="k" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="2" key_val="l" onClick={this.handleKeypress} pressKey={this.pressKey}/>
          </div>

          <div className="key-row">
            <Letter row="3" key_val="z" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="x" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="c" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="v" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="b" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="n" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="m" onClick={this.handleKeypress} pressKey={this.pressKey}/>
            <Letter row="3" key_val="←" onClick={this.handleKeypress} pressKey={this.pressKey}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Keyboard