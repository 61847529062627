import './CallbackButton.css'

export default function CallbackButton(props) {
  return (
    <div className='outer-callback-button'>
      <button className={props.enabled ? 'callback-button-disabled':'callback-button'} onClick={props.onClick} style={props.width ? {width: props.width} : {}}>
        <p className='callback-button-text unselectable'>{props.innerText}</p>
      </button>
    </div>
  )
}