import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCi6OX8RvFyiBthgu-J1Yej26lgv7_1a1Y",
  authDomain: "play-wordle-bot.firebaseapp.com",
  projectId: "play-wordle-bot",
  storageBucket: "play-wordle-bot.appspot.com",
  messagingSenderId: "240272446951",
  appId: "1:240272446951:web:4457f254cb78d441fed942",
  measurementId: "G-5C784GY03B"
}

const firebaseApp = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(firebaseApp)
const firebaseDatabase = getDatabase(firebaseApp)
const firestore = getFirestore(firebaseApp)

export {firebaseApp, firebaseAuth, firebaseDatabase, firestore};