import { getAnswerList, getGuessList, getInOrderAnswers } from "./WordFunctions"

let guessList = getGuessList()
let answerList = getAnswerList()
let inOrderAnswers = getInOrderAnswers()

export function validateGuess(guess) {
    return guessList.includes(guess)
}

export function validateAnswer(answer) {
    return answerList.includes(answer)
}

function convertTZ(date) {
    let tzString = 'America/Los_Angeles'
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

export function getTodaysWordle() {
    let currTime = Date.now() / 1000
    let secondsOffset = (convertTZ(new Date()).getTime() - Date.now()) / 1000
    for (let i=0; i<inOrderAnswers.length; i++) {
        if (inOrderAnswers[i].epoch + secondsOffset > currTime) {
            return inOrderAnswers[i-1].word.toLowerCase()
        }
    }

    return ""
}

export function getSpecificWordle(dayNumber) {
    if (inOrderAnswers[dayNumber].number === dayNumber) {
        return inOrderAnswers[dayNumber].word
    } else {
        for (let i=0; i<inOrderAnswers.length; i++) {
            if (inOrderAnswers[i].number === dayNumber) {
                return inOrderAnswers[i].word
            }
        }

        return null
    }
}

export function getTodaysNumber() {
    let currTime = Date.now() / 1000
    let secondsOffset = (convertTZ(new Date()).getTime() - Date.now()) / 1000
    for (let i=0; i<inOrderAnswers.length; i++) {
        if (inOrderAnswers[i].epoch + secondsOffset > currTime) {
            return inOrderAnswers[i-1].number
        }
    }

    return null
}

function getNumChars(word, letter) {
    let num = 0
    for (let i=0; i<word.length; i++) {
        if (word[i] === letter) {
            num += 1
        }
    }
    return num
}

function getDetractingChars(word, letter, colorsList) {
    let num = 0
    for (let i=0; i<word.length; i++) {
        if (letter === word[i]) {
            if (colorsList[i] === "g" || colorsList[i] === "y") {
                num += 1
            }
        }
    }
    return num
}

export function genTodaysColorList(guessList) {
    return guessList.map(guess => genTodaysColors(guess))
}

export function genTodaysColors(guess) {
    const todaysWord = getTodaysWordle()
    return genColors(guess, todaysWord)
}

export function simpleNarrowAnswer(guessList) {
    let remAnswers = getAnswerList()
    let todaysAnswer = getTodaysWordle()
    let colorList = []

    guessList.forEach((guess) => {
        colorList.push(genColors(guess, todaysAnswer))
    })

    return narrowAnswerList(guessList, colorList, remAnswers)
}

export function narrowAnswerList(guessList, colorList, remainingAnswers) {
  let newRemaining = []

  for (let i=0; i<remainingAnswers.length; i++) {
    if (isValidColors(guessList, colorList, remainingAnswers[i])) {
      newRemaining.push(remainingAnswers[i])
    }
  }

  return newRemaining
}

// this is like narrowAnswerList but you just give it one guess, 
// the current list of remaining answers, and optionally the wordleAnswer
// and it gives you back a new narrowed list of remaining answers. If you
// do not include the wordleAnswer it assumes todays word is the wordleAnswer.
export function simpleNarrowAnswers(guess, currentRemaining, wordleAnswer=null) {
    if (wordleAnswer === null) {
        wordleAnswer = getTodaysWordle()
    }

    let colors = genColors(guess, wordleAnswer)
    
    return narrowAnswerList([guess], [colors], currentRemaining)
}

// returns true if guess could be the answer based on the guesses
// and colors we have seen
export function isValidColors(guessList, colorList, guess) {
  for (let i=0; i<guessList.length; i++) {
    if (guessList[i] === "") {
      continue
    }
    let guessColors = genColors(guessList[i], guess)

    if (colorList[i] !== guessColors) {
      return false
    }
  }

  return true
}

export function simpleGenColors(guess) {
    let answer = getTodaysWordle()
    return genColors(guess, answer)
}

export function genColors(guess, answer) {
    let colorsList = Array.apply("", Array(5))

    // fill in black and green colors
    for (let i=0; i<guess.length; i++) {
        if (guess[i] === answer[i]) {
            colorsList[i] = 'g'
        } else if (!answer.includes(guess[i])) {
            colorsList[i] = 'b'
        }
    }

    // fill in yellow colors
    for (let i=0; i<guess.length; i++) {
        if (colorsList[i] !== undefined) {
            continue
        }

        let a = getNumChars(answer, guess[i])
        let b = getDetractingChars(guess, guess[i], colorsList)

        if (a-b > 0) {
            colorsList[i] = "y"
        } else {
            colorsList[i] = "b"
        }
    }

    return colorsList.join('')
}