import { firebaseAuth, firebaseDatabase } from "../../Firebase";
import { Navigate, Outlet } from "react-router-dom";
import { get, child, ref } from "firebase/database";
import { useState } from "react";
import axios from 'axios'

export default function Paywall(props) {
  const [redirect, setRedirect] = useState(false)

  if (redirect) {
    return <Navigate to="/paywall" />
  }

  async function checkForRedirect() {
    let snapshot = await get(child(ref(firebaseDatabase), '/userHasPaid/' + firebaseAuth.currentUser.uid))
    if (!snapshot.val()) {
      snapshot = await get(child(ref(firebaseDatabase), '/userTrialTracker/'+firebaseAuth.currentUser.uid))

      let time = null
      try {
        time = await axios.get("http://worldclockapi.com/api/json/utc/now")
        time = Date.parse(time.data.currentDateTime)
      } catch (error) {
        time = Date.now()
      }

      if (!snapshot.val()) {
        setRedirect(true)
      } else if (snapshot.val() < time) {
        setRedirect(true)
      }
    }
  }


  if (firebaseAuth.currentUser) {
    checkForRedirect()
  } else {
    console.log("Redirect because not signed in")
    setRedirect(true)
  }

  if (!redirect) {
    return (
      <Outlet />
    )
  }
}