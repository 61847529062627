import { Navigate } from 'react-router-dom';
import { firebaseAuth } from '../../Firebase'
import { signOut } from 'firebase/auth';
import Button from '../../components/buttons/Button';
import './PaidHome.css'
import { useState } from 'react';
import { firebaseDatabase, firebaseApp } from '../../Firebase';
import { ref, get, child } from 'firebase/database'
import { getTodaysNumber, simpleGenColors } from '../../components/helper_functions/WordFrontend';
import WordleBox from '../../components/wordlebox/WordleBox';
import { getTodaysGuesses } from '../../components/helper_functions/WordleBot';
import ProfileStats from '../../components/dataviz/ProfileStats';
import { Link } from 'react-router-dom'
import CallbackButton from '../../components/buttons/CallbackButton';
import { useEffect } from 'react';
import { createCheckoutSession, getStripePayments } from '@stripe/firestore-stripe-payments'

function NavOptions(props) {
  let navbtn = props.enteredToday ? (
    <div className='paid-home-spacer'>
      <Button link='/pro/analyze-guesses' text='Analyze Guesses' width={document.body.clientWidth>620?'150px':'150px'} />
    </div>
  ) : (
    <div className='paid-home-spacer'>
      <Button link='/pro/enter-guesses' text='Enter Guesses' width={document.body.clientWidth>620?'150px':'150px'} />
    </div>
  )
  return navbtn
}

function UserStats(props) {
  let displayName = props.stats.username
  if (displayName === firebaseAuth.currentUser.displayName) {
    displayName = "you"
  }

  return (
    <div className='outer-stats-wrapper'>
      <div className='inner-stats-wrapper'>
        <p className='post-stats-text'>As of today, <Link to={"/pro/profile/"+(displayName === "you" ? firebaseAuth.currentUser.displayName:displayName.toLowerCase())} style={{color: "rgb(255, 255, 161)"}}>{displayName}</Link> 
        &nbsp;{props.stats.username === firebaseAuth.currentUser.displayName ? 'have' : 'has'} completed {props.stats.numPuzzles} puzzles while using
        an average of {props.stats.avgGuesses} guesses each time.</p>
      </div>
    </div>
  )
}

function WordleBoxWrapper(props) {
  /*
   * Box array must have objects with the following:
   * {
   *  letter: ascii letter in the box
   *  box_class: 'box-inactive', 'box-filled-green', 'box-filled-yellow', 'box-filled-black'
   * }
   * 
   * The array must have a length of thirty.
   */
  let boxes = []

  for (let i=0; i<props.guessList.length; i++) {
    let colors = simpleGenColors(props.guessList[i])
    for (let j=0; j<props.guessList[i].length; j++) {
      if (colors[j] === 'g') {
        boxes.push({
          letter: props.guessList[i][j],
          box_class: 'box box-filled-green',
        })
      } else if (colors[j] === 'y') {
        boxes.push({
          letter: props.guessList[i][j],
          box_class: 'box box-filled-yellow',
        })
      } else if (colors[j] === 'b') {
        boxes.push({
          letter: props.guessList[i][j],
          box_class: 'box box-filled-black',
        })
      }
    }
  }

  for (let i=boxes.length; i<30; i++) {
    boxes.push({
      letter: ' ',
      box_class: 'box box-inactive',
    })
  }

  return (
    <div className='wordle-box-wrapper'>
      <WordleBox boxes={boxes} minimalView={true} />
    </div>
  )
}

function FeedPost(props) {
  const [isExpanded, setIsExpanded] = useState(document.body.clientWidth > 640)

  let scoreArr = [{symbol: '0️⃣', color: '#d38cff'}, 
                  {symbol: '1️⃣', color: '#d38cff'},
                  {symbol: '2️⃣', color: '#8cff9a'}, 
                  {symbol: '3️⃣', color: '#58c454'},
                  {symbol: '4️⃣', color: '#bccc52'},
                  {symbol: '5️⃣', color: '#dbaf48'},
                  {symbol: '6️⃣', color: '#db9748'},
                  {symbol: '7️⃣', color: '#db6d48'}]
  
  let displayTime = ''
  if (props.timestamp === 0) {
    displayTime = '12:00am'
  } else {
    let conv_timestamp = new Date(props.timestamp)
    let hours = conv_timestamp.getHours()
    let ending = ''
    if (hours < 12) {
      ending = 'am'
    } else {
      ending = 'pm'
    }
    if (hours > 12) {
      hours -= 12
    } else if (hours === 0) {
      hours = 12
    }

    let minutes = conv_timestamp.getMinutes()
    if (minutes < 10) {
      minutes = '0' + minutes
    }

    displayTime = hours + ':' + minutes + ending
  }

  if (!isExpanded) {
    return (
      <div className='feed-post-wrapper' 
        onClick={() => setIsExpanded(!isExpanded)}>
        <div className='post-header-bar'>
          <div className='user-details-wrapper'>
            <Link to={'/pro/profile/' + props.username.toLowerCase()} className='post-user-details'>
              <p className='post-user-name'>{props.username} {(props.username === firebaseAuth.currentUser.displayName) ? <span style={{color: 'lightgreen'}}> (you)</span>:''}
                <span className='mobile-time-display'>&nbsp;&nbsp;{displayTime}</span></p>
            </Link>
          </div>
          <div className='post-metadata-wrapper'>
            <p className='post-upload-time'><span className='upload-time-color'>{displayTime}</span>&nbsp;&nbsp;<span style={{color: scoreArr[props.guessList.length].color}} className='guess-num-color'>{scoreArr[props.guessList.length].symbol}</span></p>
          </div>
        </div>
        <div className='post-body-wrapper'>
          <div className='post-body-inner'>
            <div className='post-body-guesses'>
              <WordleBoxWrapper guessList={[props.guessList[0]]}/>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='feed-post-wrapper'
        onClick={() => setIsExpanded(!isExpanded)}>
        <div className='post-header-bar'>
          <div className='user-details-wrapper'>
            <Link to={'/pro/profile/' + props.username.toLowerCase()} className='post-user-details'>
              <p className='post-user-name'>{props.username} {(props.username === firebaseAuth.currentUser.displayName) ? <span style={{color: 'lightgreen'}}> (you)</span>:''}
                <span className='mobile-time-display'>&nbsp;&nbsp;{displayTime}</span></p>
            </Link>
          </div>
          <div className='post-metadata-wrapper'>
            <p className='post-upload-time'><span className='upload-time-color'>{displayTime}</span>&nbsp;&nbsp;<span style={{color: scoreArr[props.guessList.length].color}} className='guess-num-color'>{scoreArr[props.guessList.length].symbol}</span></p>
          </div>
        </div>

        <div className='post-body-wrapper'>
          <div className='post-body-inner'>
            <div className='post-body-guesses'>
              <WordleBoxWrapper guessList={props.guessList}/>
            </div>

            <div className='post-body-stats'>
              <UserStats stats={props.stats} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function getFriends() {
  return new Promise((resolve, reject) => {
    get(child(ref(firebaseDatabase), 'IdFollowsUsers/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
      if (snapshot.exists()) {
        let userUIDs = Object.keys(snapshot.val())

        let friends = []
        userUIDs.forEach((value, index) => {
          if (snapshot.val()[value]) {
            friends.push(value)
          }
        })

        resolve(friends)
      } else {
        reject("No friends found")
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

function getFriendsPosts() {
  return new Promise(async (resolve, reject) => {
    let friends = await getFriends()

    if (!friends) {
      resolve([])
    }

    // Get friend's posts
    let postList = []
    for (const friend of friends) {
      let snapshot = await get(child(ref(firebaseDatabase), 'userGuesses/' + friend + '/' + getTodaysNumber()))
      if (snapshot.exists()) {
        postList.push({
          guessList: snapshot.val().split("-"),
          uid: friend
        })
      }
    }

    for (let i=0; i<postList.length; i++) {
      let snapshot = await get(child(ref(firebaseDatabase), 'userTotals/' + postList[i].uid))

      if (snapshot.exists()) {
        let userSnapshot = await get(child(ref(firebaseDatabase), 'keyUsernames/' + postList[i].uid))
        let username = null
        if (userSnapshot.exists()) {
          username = userSnapshot.val()
        } else {
          continue
        }

        postList[i].stats = {
          numPuzzles: snapshot.val().numPuzzles,
          avgGuesses: Math.round((snapshot.val().numGuesses / snapshot.val().numPuzzles)*100)/100,
          username: username
        }
      }
    }

    // Get your post
    let snapshot = await get(child(ref(firebaseDatabase), 'userGuesses/' + firebaseAuth.currentUser.uid + '/' + getTodaysNumber()))
    if (snapshot.exists()) {
      postList.push({
        guessList: snapshot.val().split("-"),
        uid: firebaseAuth.currentUser.uid,
      })
      snapshot = await get(child(ref(firebaseDatabase), 'userTotals/' + firebaseAuth.currentUser.uid))

      if (snapshot.exists()) {
        postList[postList.length-1].stats = {
          numPuzzles: snapshot.val().numPuzzles,
          avgGuesses: Math.round((snapshot.val().numGuesses / snapshot.val().numPuzzles)*100)/100,
          username: firebaseAuth.currentUser.displayName
        }
      }
    }

    // Add timestamps for each post
    for (let i=0; i<postList.length; i++) {
      let timestamp = await get(child(ref(firebaseDatabase), 'wordleVerifications/'+postList[i].uid))

      if (timestamp.exists()) {
        postList[i].timestamp = timestamp.val().lastWordle
      }
    }

    // Sort posts based on timestamp
    postList.sort((a, b) => b.timestamp - a.timestamp)

    resolve(postList)
  })
}

function PersonalFeed(props) {
  const [friendsPosts, setFriendsPosts] = useState(false)
  const [stats, setStats] = useState({
    numPuzzles: 0,
    avgGuesses: 0,
    username: 'WordleBot',
    botGuessList: ["blank"]
  })

  if (!props.enteredToday) {
    return (
      <div className='outer-feed-wrapper'>
        <br />
        <h2>Enter your guesses to unlock today's feed!</h2>
      </div>
    )
  }

  if (stats.numPuzzles === 0) {
    get(child(ref(firebaseDatabase), 'userTotals/wordlebot')).then((snapshot) => {
      if (snapshot.exists()) {
        setStats(
          {
            numPuzzles: snapshot.val().numPuzzles,
            avgGuesses: Math.round((snapshot.val().numGuesses / snapshot.val().numPuzzles)*100)/100,
            username: 'WordleBot',
            botGuessList: getTodaysGuesses().guessList
          }
        )
      }
    })
  }

  if (friendsPosts === false) {
    getFriendsPosts().then((posts) => {
      setFriendsPosts(posts)
    })
  }

  if (friendsPosts === false) {
    return (
      <div className='outer-feed-wrapper'>
        <br />
        <h2 style={{marginBottom: '6px'}}>Daily Feed</h2>
        <FeedPost username='WordleBot' timestamp={0} stats={stats} guessList={stats.botGuessList} />
      </div>
    )
  } else {
    return (
      <div className='outer-feed-wrapper'>
        <br />
        <h2 style={{marginBottom: '6px'}}>Daily Feed</h2>
        <FeedPost username='WordleBot' timestamp={0} stats={stats} guessList={stats.botGuessList} />
        {
          friendsPosts.map((post, index) => {
            return (
              <FeedPost key={index} timestamp={post.timestamp} username={post.stats.username} stats={post.stats} guessList={post.guessList} />
            )
          })
        }
      </div>
    )
  }
}

function PayNowButton(props) {
  const [mode, setMode] = useState('clickable')
  return (
    <span className={mode+' pay-now-button'} style={{textDecoration: "underline", display: "inline", color: "lightgreen", fontSize: "17.5px"}} onClick={() => {
      async function redirectToStripe() {
        const payments = getStripePayments(firebaseApp, {
          productsCollection: "products",
          customersCollection: "customers",
        })

        createCheckoutSession(payments, {
          price: 'price_1LjWwIIdWgsbsOOIxU47ogt3',
          mode: 'payment',
        }).then((session) => {
          window.location.href = session.url
        })
      }

      redirectToStripe()
      setMode('busy')
    }}>Pay now?</span>
  )
}

export default function PaidHome() {
  const [redirect, setRedirect] = useState(false)
  const [enteredToday, setEnteredToday] = useState({status: false, verified: false})
  const [clipboard, setClipboard] = useState(false)
  const [trialDaysRem, setTrialDaysRem] = useState({days: false, verified: false})

  useEffect(() => {
    if (!enteredToday.verified) {
      get(child(ref(firebaseDatabase), 'wordleVerifications/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
        if(snapshot.exists()) {
          let currDate = new Date()
          currDate.setHours(0, 0, 0, 0)
          if(snapshot.val().lastWordle > currDate.getTime()) {
            setEnteredToday({status: true, verified: true})
          }
        }
      }).catch((error) => {
        console.log("error retrieving last verification: " + error)
      })
    }

    if (!trialDaysRem.verified) {
      get(child(ref(firebaseDatabase), 'userHasPaid/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
        if (!snapshot.val()) {
          get(child(ref(firebaseDatabase), 'userTrialTracker/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
            if (snapshot.exists()) {
              let days = Math.round((snapshot.val()-Date.now()) / 1000 / 60 / 60 / 24)
              setTrialDaysRem({days: days, verified: true})
            }
          })
        }
      })
    }
  })

  if (firebaseAuth.currentUser == null) {
    return (
      <Navigate to={{pathname: '/'}} />
    )
  }

  if (!redirect) {
    return (
      <main className='paid-home-wrapper'>
        <div className={clipboard ? 'copied-to-clipboard-visible':'copied-to-clipboard-hidden'}>
          <p className='notification-text'>Link copied to clipboard!</p>
        </div>

        <div className='inner-home-wrapper'>
          <div className='paid-home-title'>
            <h2 className='paid-title-text'> Wordle Clubhouse </h2>
            <p className='current-user-text'>Welcome back, <Link to={'/pro/profile/'+firebaseAuth.currentUser.displayName.toLowerCase()} style={{color: 'yellow'}}>{firebaseAuth.currentUser.displayName}</Link>.
              <span className='sign-out-text' onClick={() => {
                signOut(firebaseAuth)
                setRedirect(true)
              }}>&nbsp;Sign out?</span></p>
            {trialDaysRem.days?<p className='trial-days-rem-text'>
              <span style={{color: "yellow"}}>{trialDaysRem.days}</span> days left in your trial. <PayNowButton />
            </p>:''}
          </div>

          <ProfileStats uid={firebaseAuth.currentUser.uid} />

          <div className='paid-nav-buttons'>
            <NavOptions enteredToday={enteredToday.status} />

            <div className='paid-home-spacer'>
              <Button color="lightgreen" text="My Friends" link={"/pro/profile/" + firebaseAuth.currentUser.displayName.toLowerCase()} width="150px" />
            </div>
          </div>

          <PersonalFeed enteredToday={enteredToday.status} />
        </div>
      </main>
    );
  } else {
    return (
      <Navigate to={{pathname: '/'}} />
    )
  }
}

/*
              <CallbackButton width="172px" innerText="Invite a Friend" onClick={() => {
                if (navigator.share) {
                  navigator.share({
                    title: document.title,
                    text: "Come check out the Wordle Clubhouse!",
                    url: "/"
                  })
                  .then(() => {
                  })
                  .catch(error => {
                    navigator.clipboard.writeText("playwordlebot.com")
                    setClipboard(true)
                    setTimeout(() => {
                      setClipboard(false)
                    }, 2000)
                  });
                }
              }} />
*/
