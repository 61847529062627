import { useState } from 'react'
import { get, child, ref } from 'firebase/database'
import { firebaseDatabase } from '../../Firebase'
import NewBarChart from './NewBarChart'
import './ProfileStats.css'

// so I don't have to mess with the
// barchart I copied from here:
// https://www.createwithdata.com/react-bar-chart
function BarChartWrapper(props) {
  if (props.showChart) {
    return (
      <main style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <NewBarChart barList={props.barList} />
      </main>
    )
  } else {
    return (
      <h3>Loading chart...</h3>
    )
  }
}

export default function ProfileStats(props) {
  const [numPuzzles, setNumPuzzles] = useState(0)
  const [avgGuesses, setAvgGuesses] = useState(0)
  const [barList, setBarList] = useState({
    associatedUser: props.uid,
    list: [
      {name: '1', value: 50},
      {name: '2', value: 50},
      {name: '3', value: 50},
      {name: '4', value: 50},
      {name: '5', value: 50},
      {name: '6', value: 50},
      {name: 'F', value: 50},
    ]})
  const [dataPopulated, setDataPopulated] = useState(false)

  if (!dataPopulated || barList.associatedUser !== props.uid) {
    get(child(ref(firebaseDatabase), 'userTotals/' + props.uid)).then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val()
        setNumPuzzles(data.numPuzzles)
        setAvgGuesses(Math.round((data.numGuesses / data.numPuzzles)*100)/100)
        let newBarList = []
        newBarList.push({name: '1', value: data.numOnes})
        newBarList.push({name: '2', value: data.numTwos})
        newBarList.push({name: '3', value: data.numThrees})
        newBarList.push({name: '4', value: data.numFours})
        newBarList.push({name: '5', value: data.numFives})
        newBarList.push({name: '6', value: data.numSixes})
        newBarList.push({name: 'F', value: data.numFails})
        setBarList({
          associatedUser: props.uid,
          list: newBarList})
        setDataPopulated(true)
      }
    })
  }

  return (
    <div className='profile-stats-wrapper'>
      <p className='profile-stats-text'>Puzzles Completed: {numPuzzles}</p>
      <p className='profile-stats-text'>Average Guesses: {avgGuesses}</p>
      <BarChartWrapper barList={barList.list} showChart={dataPopulated}/>
    </div>
  )
}