import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

export default function Button(props) {
  let buttonStyles = {}
  if (props.width) {
    buttonStyles["width"] = props.width
  }
  if (props.color) {
    buttonStyles["backgroundColor"] = props.color
  }
  return(
     <span>
        <Link to={props.link}>
            <div className="inner-button" style={buttonStyles}>
              <p className="btn-text"> {props.text} </p>
            </div>
        </Link>
      </span>
  )
}
