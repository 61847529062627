import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import './index.css';
import App from './App';
import FreeEnterGuesses from './routes/free_routes/EnterGuesses'
import FreeViewWordleBot from './routes/free_routes/ViewWordleBot'
import PaidHome from './routes/paid_routes/PaidHome'
import PaidEnterGuesses from './routes/paid_routes/PaidEnterGuesses'
import PaidViewWordleBot from './routes/paid_routes/PaidViewWordleBot'
import AnalyzeGuesses from './routes/paid_routes/AnalyzeGuesses'
import LoginPage from './routes/LoginPage';
import SignupPage from './routes/SignupPage';
import ProfilePage from './routes/paid_routes/ProfilePage';
import { store } from './store';
import { Provider } from 'react-redux';
import PaywallPage from './routes/PaywallPage';
import Paywall from './components/invisible/Paywall';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/basic/enter-guesses" element={<FreeEnterGuesses />} />
        <Route path="/basic/view-wordle-bot" element={<FreeViewWordleBot />} />

        <Route path="/pro" element={<Paywall />}>
          <Route path="home" element={<PaidHome />} />
          <Route path="enter-guesses" element={<PaidEnterGuesses />} />
          <Route path="view-wordle-bot" element={<PaidViewWordleBot />} />
          <Route path="analyze-guesses" element={<AnalyzeGuesses />} />
          <Route path="profile/:username" element = {<ProfilePage key={Math.random()} />} />
        </Route>

        <Route path="/paywall" element={<PaywallPage />}/>

        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
