import React, { useState } from 'react';
import { Outlet, Link, Navigate } from "react-router-dom";
import './App.css';
import Button from './components/buttons/Button';
import {get,child,ref} from 'firebase/database'
/*
import { useSelector, useDispatch } from 'react-redux';
import { increment } from './features/counterSlice';
import { toggle } from './features/verifiedSlice';
*/
import { firebaseAuth, firebaseDatabase } from './Firebase'
import { signOut } from 'firebase/auth';

/*
Here are the items we pointed to:
9.  Wordle Challenge with Friends.
10. Analyze old days
11. Analyze custom guesses

Backlog:
7.  Pop-up behind percentage placement of guess showing better guesses.
    This was not feasible because of client performance constraints.
*/

export default function App() {
  /*
  const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch()

  const isVerified = useSelector((state) => state.verified.value)
  */
  const [redirectStatus, setRedirectStatus] = useState(false)
  const [username, setUsername] = useState('')

  firebaseAuth.onAuthStateChanged((user) => {
    if(user != null) {
      setUsername(firebaseAuth.currentUser.displayName)
      get(child(ref(firebaseDatabase), "userHasPaid/" + firebaseAuth.currentUser.uid)).then((snapshot) => {
        if(snapshot.val()) {
          setRedirectStatus(true)
        }
      })
    } else {
      setUsername('')
    }
  })

  if (!redirectStatus) {
    return (
      <div className="home-div">
        <h1 className="page-title"> Welcome to the <a className="yellow-wordle-link" href="https://www.nytimes.com/games/wordle/index.html">WORDLE</a> club! </h1>

        {(firebaseAuth.currentUser ? (
          <p style={{margin: '0px', marginBottom: '10px', fontSize: '20px'}}>Logged in as&nbsp;
          <Link to={"/pro/profile/"+username} style={{color: 'yellow'}}>{username}</Link>.
          <span className='sign-out-text' onClick={() => {
            signOut(firebaseAuth)
          }}>&nbsp;Sign out?</span></p>
        ): '')}

        <br />
        <Button text="Play the WORDLE Bot" link="/basic/enter-guesses" />
        <br />
        <Button text="Enter the Clubhouse!" link="/pro/home"/>
        <br />
        <br />
        <span>
          <Link to="/login">
            <p className="login-text"> Already a member? Log in here. </p>
          </Link>
        </span>
        <Outlet />
      </div>
    );
  } else {
    return (
      <Navigate to={{pathname: "/pro/home"}} />
    )
  }
}

/*
      <h2> {count} </h2>
      <h2> frick: {isVerified.toString()} </h2>

      <button onClick={() => dispatch(toggle())}>
          Toggle
      </button>

      <button onClick={() => dispatch(increment())}>
          Increment
      </button>
*/