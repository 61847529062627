import { Navigate, useNavigate } from 'react-router-dom';
import './AnalyzeGuesses.css'
import Button from '../../components/buttons/Button';
import { firebaseAuth } from '../../Firebase';
import { firebaseDatabase } from '../../Firebase';
import { ref, get, child } from 'firebase/database'
import { useState } from 'react';
import { getTodaysNumber, getTodaysWordle, simpleGenColors, simpleNarrowAnswer, simpleNarrowAnswers } from '../../components/helper_functions/WordFrontend';

function StyledLetter(props) {
  let colors = simpleGenColors(props.guess)

  if (colors[props.guessInd] === 'b') {
    return <span style={{color: 'rgb(150, 150, 150)'}}>{props.guess[props.guessInd].toUpperCase()}</span>
  } else if (colors[props.guessInd] === 'y') {
    return <span style={{color: '#f5f507'}}>{props.guess[props.guessInd].toUpperCase()}</span>
  } else {
    return <span style={{color: '#4fe041'}}>{props.guess[props.guessInd].toUpperCase()}</span>
  }
}

function PossibleGuess(props) {
  let [displayModal, setDisplayModal] = useState(false)

  let color = ""
  let colorDesc = ""
  if (props.word === getTodaysWordle()) {
    color = "rgb(170, 63, 170)"
  } else if (props.isNextGuess) {
    color = "orange"
    colorDesc = "We colored this word orange because it was your next guess."
  } else if (props.remAfter <= 1) {
    color = "lightgreen"
    colorDesc = "We colored this word green because there would be only one word remaining after it is guessed."
  } else if (props.remAfter <= 4) {
    color = "yellow"
    colorDesc = "We colored this word yellow because there would be less than four words remaining after it is guessed."
  } else if (props.remAfter <= 7) {
    color = "red"
    colorDesc = "We colored this word red because there would be less than seven words remaining after it is guessed."
  } else {
    color = "lightgrey"
    colorDesc = "We colored this word white because there would be more than seven words remaining after it is guessed."
  }

  if (props.word === getTodaysWordle()) {
    return (
      <main className='guess-word-wrapper'>
        <div className='guess-info-modal' style={displayModal ? {display: "flex"} : {display: "none"}}>
          <div className='modal-click-intercepter' onClick={() => setDisplayModal(!displayModal)}/>
          <div className='inner-guess-modal'>
            <div className='guess-modal-text-wrapper'>
              <h1 className='guess-modal-header'>{props.word.toUpperCase()}</h1>
              <p className='guess-modal-text' style={{fontSize: "18px"}}>This guess is today's WORDLE solution!</p>
            </div>
          </div>
        </div>
        <div className='words-rem-box' onClick={() => setDisplayModal(!displayModal)}>
          <p style={{margin: '0px', color: color, fontFamily: 'monospace, Clear Sans,Helvetica Neue,Arial,sans-serif'}}>{props.word}</p>
        </div>
      </main>
    )
  } else if (props.isNextGuess) {
    return (
      <main className='guess-word-wrapper'>
        <div className='guess-info-modal' style={displayModal ? {display: "flex"} : {display: "none"}}>
          <div className='modal-click-intercepter' onClick={() => setDisplayModal(!displayModal)}/>
          <div className='inner-guess-modal'>
            <div className='guess-modal-text-wrapper'>
              <h1 className='guess-modal-header'>{props.word.toUpperCase()}</h1>
              <p className='guess-modal-text'>You guessed <span style={{color: color}}>{props.word}</span> at 
                this point, causing there to be {props.remAfter} word{props.remAfter === 1 ? "" : "s"} remaining afterward.</p>
              <p className='guess-modal-text'>{colorDesc}</p>
            </div>
          </div>
        </div>
        <div className='words-rem-box' onClick={() => setDisplayModal(!displayModal)}>
          <p style={{margin: '0px', color: color, fontFamily: 'monospace, Clear Sans,Helvetica Neue,Arial,sans-serif'}}>{props.word}</p>
        </div>
      </main>
    )
  } else {
    return (
      <main className='guess-word-wrapper'>
        <div className='guess-info-modal' style={displayModal ? {display: "flex"} : {display: "none"}}>
          <div className='modal-click-intercepter' onClick={() => setDisplayModal(!displayModal)}/>
          <div className='inner-guess-modal'>
            <div className='guess-modal-text-wrapper'>
              <h1 className='guess-modal-header'>{props.word.toUpperCase()}</h1>
              <p className='guess-modal-text'>If you had guessed <span style={{color: color}}>{props.word}</span> at 
                this point, there would have been {props.remAfter} word{props.remAfter === 1 ? "" : "s"} remaining afterward.</p>
              <p className='guess-modal-text'>{colorDesc}</p>
            </div>
          </div>
        </div>
        <div className='words-rem-box' onClick={() => setDisplayModal(!displayModal)}>
          <p style={{margin: '0px', color: color, fontFamily: 'monospace, Clear Sans,Helvetica Neue,Arial,sans-serif'}}>{props.word}</p>
        </div>
      </main>
    )
  }
}

function WordList(props) {
  const [isExpanded, setIsExpanded] = useState(false)

  if (props.words.length <= 20 && !isExpanded) {
    setIsExpanded(true)
  }

  let allWords = props.words
  allWords.sort((a, b) => simpleNarrowAnswers(a, props.words).length - simpleNarrowAnswers(b, props.words).length)
  let shortWords = allWords.slice(0, 20)
  const deExpandMsg = allWords.length > 20 ? <p className='exp-words-link' onClick={()=>setIsExpanded(false)}>...hide {allWords.length-20} words.</p> : null

  if (!isExpanded) {
    return (
      <div className='outer-words-wrapper'>
        <div className='words-rem-box-wrapper'>
            {shortWords.map((word, i) => {
              return <PossibleGuess remAfter={simpleNarrowAnswers(word, props.words).length} key={i} word={word} isNextGuess={word===props.nextGuess} />
            })}
            <p className='exp-words-link' onClick={()=>setIsExpanded(true)}>...and {props.words.length-20} more!</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className='outer-words-wrapper'>
        <div className='words-rem-box-wrapper'>
            {allWords.map((word, i) => {
              return <PossibleGuess remAfter={simpleNarrowAnswers(word, props.words).length} key={i} word={word} isNextGuess={word===props.nextGuess} />
            })}
            {deExpandMsg}
        </div>
      </div>
    )
  }
}

function GuessInfo(props) {
  let trimmedGuessList = props.todaysGuesses.slice(0, props.index+1)
  let remAnswers = simpleNarrowAnswer(trimmedGuessList)
  let guessArr = [...props.todaysGuesses[props.index]]

  let nextGuess = props.todaysGuesses.length > props.index+1 ? props.todaysGuesses[props.index+1] : null

  if (props.todaysGuesses[props.index] !== getTodaysWordle()) {
    return (
      <div className='guess-info-box'>
        <div className='guess-list-header' key={props.index}>{
          guessArr.map((letter, ind) => {
            return <StyledLetter key={ind} guess={props.todaysGuesses[props.index]} guessInd={ind} />
          })
        }</div>

        <p className='words-rem-text'>There w{remAnswers.length===1 ? 'as' : 'ere'} <span style={{color: 'lightgreen'}}>{remAnswers.length}</span> word{remAnswers.length === 1 ? '' : 's'} remaining after this guess:</p>

        <WordList words={remAnswers} nextGuess={nextGuess} />
      </div>
    )
  } else {
    return (
      <div className='guess-info-box'>
        <div className='guess-list-header' key={props.index}>{
          guessArr.map((letter, ind) => {
            return <StyledLetter key={ind} guess={props.todaysGuesses[props.index]} guessInd={ind} />
          })
        }</div>

        <p className='words-rem-text'>This was today's <a href='https://www.nytimes.com/games/wordle/index.html' style={{'color': 'yellow'}}>Wordle&nbsp;</a>solution.</p>
      </div>
    )
  }
}

export default function AnalyzeGuesses() {
  const [guessMap, setGuessMap] = useState(null)
  let navigate = useNavigate()

  function navAway() {
    navigate("/pro/enter-guesses")
  }

  if (firebaseAuth.currentUser == null) {
    return (
      <Navigate to='/' />
    )
  }

  if (guessMap == null) {
    get(child(ref(firebaseDatabase), 'userGuesses/' + firebaseAuth.currentUser.uid)).then((snapshot) => {
      if (snapshot.exists()) {
        let expGuessMap = {}
        let snapshotVal = snapshot.val()
        for (const property in snapshotVal) {
          let guessStr = snapshotVal[property]
          let guessArr = guessStr.split("-")
          expGuessMap[property] = guessArr
        }
        if (expGuessMap[getTodaysNumber()]) {
          setGuessMap(expGuessMap)
        } else {
          setGuessMap('NoGuessToday')
        }
      } else {
        setGuessMap('NoGuessToday')
      }
    })
  }

  function renderAnalysis() {
    if (guessMap === 'NoGuessToday') {
      return (
      <div className='outer-analysis-wrapper'>
        <div>
          <h2> Uh oh, we could not find any guesses for today. </h2>
          <p className='nav-away-hint' onClick={navAway} href="/pro/enter-guesses"> Try entering your guesses <span style={{textDecoration: 'underline', color: 'yellow'}}>here</span> and coming back. </p>
        </div>
      </div>
      )
      
    }
    let todaysWordleNum = getTodaysNumber()

    return (
      <main className='outer-analysis-wrapper'>
        <div className='analyze-guesses-wrapper'>
          <h1 className='analysis-header-one'><a href='https://www.nytimes.com/games/wordle/index.html' style={{'color': 'yellow'}}>Wordle&nbsp;</a><span style={{color: 'lightblue'}}>#{todaysWordleNum}</span></h1>
          <br />
          <h2 className='analysis-header-two'> Guess breakdown: </h2>
          <ul>
            {guessMap[todaysWordleNum].map((guess, index) => <GuessInfo key={index} index={index} todaysGuesses={guessMap[todaysWordleNum]} />)}
          </ul>
          <br/>
          <div className='go-home-button'>
            <Button link='/pro/home' text='Daily Feed' />
          </div>
        </div>
      </main>
    );
  }

  return guessMap ? renderAnalysis() : (
    <div className='outer-analysis-wrapper'>
      <h2> Loading past guesses... </h2>
    </div>
  )

}
