import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: false,
}

export const verifiedSlice = createSlice({
    name: 'verifiedGuesses',
    initialState,
    reducers: {
        toggle: (state) => {
            state.value = !state.value
        },
        setToVerified: (state) => {
            state.value = true
        },
        setToUnverified: (state) => {
            state.value = false
        },
    },
})

export const { toggle, setToVerified, setToUnverified } = verifiedSlice.actions
export default verifiedSlice.reducer