import { Navigate } from 'react-router-dom';
import { getTodaysNumber } from '../../components/helper_functions/WordFrontend';
import './ViewWordleBot.css'
import { useSelector } from 'react-redux';
import Button from '../../components/buttons/Button'
import BotShowcase from '../../components/wordlebox/BotShowcase';

export default function FreeViewWordleBot(props) {
  let wordleNumber = getTodaysNumber()
  let isVerified = useSelector((state) => state.verified.value)

  if (isVerified) {
    return (
      <main className="outer-bot-wrapper">
        <div className="wordle-bot-wrapper">
          <h2 className='wordle-number-title'> Wordle Bot #{ wordleNumber } </h2>
          
          <BotShowcase />

          <nav className='botshowcase-home-btn'>
            <Button text='Go Home' link='/' />
          </nav>
        </div>
      </main>
    );
  } else {
    return (
      <Navigate to='/basic/enter-guesses' />
    );
  }
}
