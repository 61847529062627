import React from 'react'
import './GuessInput.css'
import WordleBox from './WordleBox'
import CallbackButton from '../buttons/CallbackButton'

import { getTodaysWordle,
         validateGuess,
         genTodaysColorList} from '../helper_functions/WordFrontend'
import Keyboard from './Keyboard';

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

class GuessInput extends React.Component {
  constructor(props) {
    super(props)

    let boxes = []
    // fill active box
    boxes.push({
      letter: ' ',
      box_class: "box box-active",
    })

    // fill rest of the boxes
    for (let i=1; i<30; i++) {
      boxes.push({
        letter: ' ',
        box_class: "box box-inactive",
      })
    }

    // get today's WORDLE word
    this.todaysWordle = getTodaysWordle()

    // enter into state
    this.state = {boxes: boxes, active_box: 0, success: false, error: {
      error_text: "Enter your choices and press the Verify button.",
      error_class: "error-container-white"
    }}
    this.handle_keypress = this.handle_keypress.bind(this)
    this.handle_submit = this.handle_submit.bind(this)
    this.onVirtualKeyboardPress = this.onVirtualKeyboardPress.bind(this)
    this.parse_words = this.parse_words.bind(this)
    this.sendVerifyCallback = this.sendVerifyCallback.bind(this)
  } 

  onVirtualKeyboardPress(key) {
    if (this.state.success) {
      return
    }
    if (key >= 'a' && key <= 'z' && key.length === 1 && this.state.active_box < 30) {
      this.setState((prevState) => {
        let new_boxes = prevState.boxes
        new_boxes[prevState.active_box].letter = key
        new_boxes[prevState.active_box].box_class = "box box-filled"

        if (prevState.active_box < 29) {
          new_boxes[prevState.active_box+1].box_class = "box box-active"
        }

        let newError = prevState.error

        if ((prevState.active_box+1) % 5 === 0) {
          let {status, word} = this.verify_guesses()

          if (status === 0 || status === 2) {
            newError.error_text = 'Enter your choices and press the Verify button.'
            newError.error_class = 'error-container-white'
          } else if (status === 1) {
            newError.error_text = "Sorry but '" + word + "' is not a valid WORDLE guess."
            newError.error_class = 'error-container-red'
          }
        }

        return {boxes: new_boxes, active_box: prevState.active_box + 1, error: newError}
      })
    } else if ((key === 'Backspace' || key === '←') && this.state.active_box > 0) {
      this.setState((prevState) => {
        let new_boxes = prevState.boxes
        new_boxes[prevState.active_box-1].letter = ' '
        new_boxes[prevState.active_box-1].box_class = "box box-active"

        if (prevState.active_box < 30) {
          new_boxes[prevState.active_box].box_class = "box box-inactive"
        }
        
        return {boxes: new_boxes, active_box: prevState.active_box - 1}
      })
    } else if ((key === 'Enter')) {
      this.handle_submit()
    }
  }

  handle_keypress(event) {
    this.onVirtualKeyboardPress(event.key)
  }

  parse_words() {
    let words = []
    let curr_word = ""

    for (let i=0; i<30; i++) {
      if (i % 5 === 0 && i !== 0) {
        words.push(curr_word)
        curr_word = ""
      }

      if (this.state.boxes[i].letter === ' ') {
        return words
      }

      curr_word += this.state.boxes[i].letter
    }

    words.push(curr_word)
    return words
  }

  verify_guesses() {
    let wordList = this.parse_words()

    for (let i=0; i<wordList.length; i++) {
      if (!validateGuess(wordList[i])) {
        return {
          status: 1,
          word: wordList[i] 
        }
      }
    }

    if (wordList[wordList.length-1] !== this.todaysWordle && wordList.length < 6) {
      return {
        status: 2,
        word: wordList[wordList.length-1] 
      }
    }

    return {
      status: 0,
      word: ""
    }
  }

  handle_submit(event) {
    if (this.state.success) {
      return
    }

    if (this.state.active_box === 0) {
      this.setState({error: {
        error_text: "Uh oh, you didn't submit anything!",
        error_class: "error-container-red"
      }})
    } else if (this.state.active_box % 5 !== 0) {
      this.setState({error: {
        error_text: "Oh no, you entered an incomplete word!",
        error_class: "error-container-red"
      }})
    } else {
      let {status, word} = this.verify_guesses()

      if (status === 1) {
        this.setState({error: {
          error_text: "Sorry but '" + word + "' is not a valid WORDLE guess.",
          error_class: "error-container-red"
        }})
        return
      } else if (status === 2) {
        this.setState({error: {
          error_text: "Sorry but '" + word + "' is not today's WORDLE word.",
          error_class: "error-container-red"
        }})
        return
      }

      let guessList = this.parse_words()
      let colorList = genTodaysColorList(guessList)

      if (this.state.active_box < 30) {
        this.setState((prevState) => {
          let new_boxes = prevState.boxes
          new_boxes[prevState.active_box].box_class = 'box box-inactive'
          return new_boxes
        })
      }

      switch(guessList.length) {
        case 1:
          this.setState({success: true, error: {
            error_text: "Genius! There's only a 0.043% chance of guessing the WORDLE on the first try.",
            error_class: "error-container-green"
          }})
          break
        case 2:
          this.setState({success: true, error: {
            error_text: "Wow! That was impressive.",
            error_class: "error-container-green"
          }})
          break
        case 3:
          this.setState({success: true, error: {
            error_text: "Nice, that's well above average!",
            error_class: "error-container-green"
          }})
          break
        case 4:
          this.setState({success: true, error: {
            error_text: "Good job, par for the course.",
            error_class: "error-container-green"
          }})
          break
        case 5:
          this.setState({success: true, error: {
            error_text: "A little dicey, but you got it!",
            error_class: "error-container-green"
          }})
          break
        case 6:
          if (guessList[guessList.length -1] === this.todaysWordle) {
            this.setState({success: true, error: {
              error_text: "Nice job pulling that out at the end.",
              error_class: "error-container-green"
            }})
          } else {
            this.setState({success: true, error: {
              error_text: "Dang... I guess that was a hard one.",
              error_class: "error-container-green"
            }})
          }
          break
        default:
          console.log("Error, unexpected length.")
      }

      for(let i=0; i<guessList.length; i++) {
        for (let j=0; j<5; j++) {
          delay(((i*1100)+200) + (j*200)).then(() => {
            this.setState((prevState) => {
              let new_boxes = prevState.boxes
              if (colorList[i][j] === 'g') {
                new_boxes[i*5 + j].box_class = "box box-filled-green"
              } else if (colorList[i][j] === 'y') {
                new_boxes[i*5 + j].box_class = "box box-filled-yellow"
              } else if (colorList[i][j] === 'b') {
                new_boxes[i*5 + j].box_class = "box box-filled-black"
              }

              return {boxes: new_boxes}
            })
          })
        }
      }
    }
  }

  sendVerifyCallback() {
    if (this.state.success) {
      this.props.verifyCallback(this.parse_words())
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handle_keypress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handle_keypress)
  }

  render() {
    return (
      <main className='guess-input-main'>
        <WordleBox boxes={this.state.boxes} />

        <div className={this.state.error.error_class}>
          <p className='error-text'>{this.state.error.error_text}</p>
        </div>
      
        <div className='submit-buttons'>
          <CallbackButton enabled={this.state.success} innerText="Verify Solution" onClick={this.handle_submit} />
          <CallbackButton enabled={!this.state.success} innerText="Submit Guesses" onClick={this.sendVerifyCallback} />
        </div>

        <br />

        {(navigator.userAgent.match(/Android/i)||navigator.userAgent.match(/iPhone/i))?(
          <Keyboard onPress={this.onVirtualKeyboardPress}/>
        ):''}

      </main>
    );
  }
}

export default GuessInput;